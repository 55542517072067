import React, { useEffect, useRef, useState } from 'react'
import IconEdit from '../../../icons/edit';
import IconUpload from '../../../icons/upload';
import IconFileExport from '../../../icons/fileExport';
import { ModalScheduleForm } from '../components/ModalScheduleForm';
import { apiDriverApp } from '../../../../libs/apiDriverApp';
import moment from 'moment';
import IconCopyDark from '../../../icons/copyDark';
import { ModalDelete } from '../components/ModalDelete';
import LoadMask from '../../Utils/LoadMask';
import { handleFileChange, processShifts, processSchedules, processDrivers, updateResourcesForSchedules, handleDownload } from '../utils/driverSchedule';
import Swal from 'sweetalert2';
import { Dropdown } from 'semantic-ui-react';
import "semantic-ui-css/semantic.min.css";
import './styles.css';
import IconFilterAlt from '../../../icons/filterIconAlt';
import { WeeklyView } from '../components/weeklySchedule/WeeklyView';

export const Schedules = () => {
  const [showModal, setShowModal] = useState(false);
  const [schedules, setSchedules] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isCopy, setIsCopy] = useState(false);
  const [availablePilots, setAvailablePilots] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [filteredData, setFilteredData] = useState('');
  const [search, setSearch] = useState("");
  const [active, setActive] = useState("All Schedules");
  const [stations, setStations] = useState([])
  const fileInputRef = useRef();
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const onFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error('No file selected');
      return;
    }

    try {

      const loadingAlert = Swal.fire({
        title: 'Uploading...',
        text: 'Please wait while the file is being uploaded and processed.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const results = await handleFileChange(file);
      const schedules = results['Schedules'];
      const shifts = results['Shifts'];
      const employees = results['Employees'];

      const { data: dbShifts } = await apiDriverApp.get('api/hr/shifts/?shift_type=all');
      const { data: dbDrivers } = await apiDriverApp.get('/api/hr/employees/');
      const { data: exisitingSchedules } = await apiDriverApp.get('/api/hr/schedule/get_all_driver_schedules/');
      const dbMap = new Map(dbDrivers.map(item => [String(item.user.username), item]));
      schedules.forEach((uploaded) => {
        const exists = exisitingSchedules.some((dbItem) => dbItem.name === uploaded.calendar_name);
  
        if (exists) {
            throw new Error(`Schedule with name '${uploaded.calendar_name}' already exists, please check your data and try again.`);
        }
      });

      if (employees.some(emp => !emp.Employee)) {
        throw new Error("Error: There are employees without an username on the file");
      }

      if (schedules.some(schedule => !schedule.calendar_name)) {
        throw new Error("Error: Schedule without a name on the file");
      }

      employees.forEach(item => {
        const match = dbMap.get(String(item.Employee));
       
        if (!match) {
          if (item.pin_code === '') {
            throw new Error(`The Employee ${item.Employee} is missing pinCode, it is required to create a new user.`);
          }
          if (item.Job === '') {
            throw new Error(`The Employee ${item.Employee} is missing Job field, it is required to create a new user.`);
          }
          if (item.Email === '') {
            throw new Error(`The Employee ${item.Employee} is missing Email field, it is required to create a new user.`);
          }
          if (!item.FirstName) {
            throw new Error(`The Employee ${item.Employee} is missing First Name field, it is required to create a new user.`);
          }
          if (!item.LastName) {
            throw new Error(`The Employee ${item.Employee} is missing Last Name field, it is required to create a new user.`);
          }
        }
      });
      await processShifts(dbShifts, shifts);
      const updatedDrivers = await processDrivers(dbMap, employees);
      const newSchedules = await processSchedules(schedules);
      await updateResourcesForSchedules(updatedDrivers, newSchedules);
      getAllshcedules();
      event.target.value = "";
      Swal.close();
      Swal.fire('Success', 'The file was uploaded successfully', 'success');

    } catch (error) {
      console.error('Error processing the file:', error);

      Swal.close();
      if (error.response) {
        Swal.fire('Error', error.response.data.detail || 'An error occurred', 'error');
      } else if (error.request) {
        Swal.fire('Network Error', 'No response from server. Please try again later.', 'error');
      } else {
        Swal.fire('Error', error.message || 'An unexpected error occurred', 'error');
      }
      event.target.value = "";
    }
  };

  const formatShifts = (shifts) => {
    let SelectOptions = shifts.map(shift => {
      return {
        value: shift.name, label: shift.name
      }
    })
    SelectOptions.unshift({
      value: 'None',
      label: 'None'
    })
    return SelectOptions
  }

  const getShiftsforSelect = async () => {
    try {
      const { data } = await apiDriverApp.get('api/hr/shifts/');
      setShifts(data)
      setOptions(formatShifts(data))
    } catch (error) {
      setOptions(formatShifts([]))
    }

  }

  const getStations = async ()=>{
    try {
      const { data } = await apiDriverApp.get('api/hr/stations/');
      setStations(data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getAllshcedules = async () => {
    setLoading(true)
    const actualDate = moment().format('YYYY-MM-DD')
    try {
      const { data } = await apiDriverApp.get(`api/hr/schedule/get_all_driver_schedules/?start_date=${actualDate}`);
      const sortedData = data.sort((a, b) => {
        if (a.drivers.length === 0 && b.drivers.length > 0) return 1;
        if (b.drivers.length === 0 && a.drivers.length > 0) return -1;
        return 0;
      });

      setSchedules(sortedData);
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  };

  const getAvailablePilots = async () => {
    try {
      const { data } = await apiDriverApp.get('api/hr/schedule/available_employees/');
      const formattedPilots = data.map((pilot) => (
        {
          name: `${pilot.user.first_name} ${pilot.user.last_name}`,
          employee_id: pilot.resource ? pilot.resource.employee : null,
          resource_id: pilot.resource ? pilot.resource.id : null
        }
      )).filter((pilot) => pilot.employee_id !== null && pilot.resource_id !== null);
      setAvailablePilots(formattedPilots)
    } catch (error) {
      console.log(error)
    }
  }

  const categoryOptions = [
    { key: "all", text: "All", value: "all" },
    { key: "unassigned ", text: "Unassigned ", value: "Unassigned" },

  ];

  const weekDays = [
    { id: 0, name: 'Sat' },
    { id: 1, name: 'Sun' },
    { id: 2, name: 'Mon' },
    { id: 3, name: 'Tues' },
    { id: 4, name: 'Wed' },
    { id: 5, name: 'Thur' },
    { id: 6, name: 'Fri' },
  ]

  const handleFilterChange = (event, { value }) => {
    setSelectedCategory(value);
  };

  const setScheduleAction = (type, item) => {
    if (type === 'copy') {
      setIsCopy(true);
      setSelectedItem(item);
      setShowModal(true)
    } else if (type === 'edit') {
      setIsCopy(false);
      setSelectedItem(item);
      setShowModal(true)
    }
  }

  const filteredSchedules = schedules && schedules.filter((schedule) => {
    const matchesCategory =
      selectedCategory === "Unassigned"
        ? schedule.drivers.length === 0
        : true;

    const matchesSearch =
      schedule.name.toLowerCase().includes(search.toLowerCase()) ||
      schedule.drivers.some((driver) =>
        driver.name.toLowerCase().includes(search.toLowerCase())
      );
    return matchesCategory && matchesSearch;
  });


  useEffect(() => {
    getAllshcedules();
    getAvailablePilots();
    getShiftsforSelect();
    getStations();
  }, [])


  return (
    <div style={{ display: 'flex', flexDirection: 'row', padding:50}}>

      <div style={{ width: '15%', marginRight: 25, marginTop: 40 }}>
        <button
          className={`w-100 btn text-left`}
          onClick={() => setActive("All Schedules")}
          style={{
            backgroundColor: active === "All Schedules" ? "#343a40" : "#fff",
            color: active === "All Schedules" ? "#fff" : "#000",
            border: active === "All Schedules" ? "1px solid #343a40" : "1px solid #ccc",
            borderRadius: 0,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            padding: "10px"
          }}
        >
          All Schedules
        </button>
        <button
          className={`w-100 btn text-left`}
          onClick={() => setActive("Weekly Schedules")}
          style={{
            backgroundColor: active === "Weekly Schedules" ? "#343a40" : "#fff",
            color: active === "Weekly Schedules" ? "#fff" : "#000",
            border: active === "Weekly Schedules" ? "1px solid #343a40" : "1px solid #ccc",
            borderRadius: 0,
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            padding: "10px"
          }}
        >
          Weekly Schedules
        </button>
      </div>

      <div style={{ width: '80%' }}>
        <LoadMask loading={loading} blur>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3>{`Schedules - ${active === 'All Schedules' ? 'All' : 'Weekly'}`}</h3>
          </div>

          {active === 'All Schedules' ? (
            <React.Fragment>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className='d-flex align-items-center w-50'>
                  <div className="w-75">
                    <input
                      placeholder="Search..."
                      className="form-control"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <div className='px-0 justify-content-end align-items-center ml-2'>
                    <div className='justify-content-end'>
                      <Dropdown
                        options={categoryOptions}
                        onChange={handleFilterChange}
                        clearable
                        scrolling
                        className="custom-dropdown"
                        compact
                        icon={null}
                        trigger={
                          <div style={{ display: 'flex', alignItems: 'center', height: '1.3rem' }}>
                            <IconFilterAlt className="h-100" />
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center" style={{ gap: 15 }}>
                  <button
                    className="btn rounded shadow-sm d-flex flex-row align-items-center gap-2"
                    style={{ backgroundColor: '#DADADA' }}
                    onClick={() => { setSelectedItem(null), setIsCopy(true), setShowModal(true) }}
                  >
                    {/* <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                   <IconFileExport className="h-100" />
                 </div> */}
                    <span style={{ fontWeight: 'bold', margin: 0, cursor: 'pointer', marginLeft: 5, backgroundColor: '#DADADA' }}>New +</span>
                  </button>
                  <button
                    className="btn rounded shadow-sm d-flex flex-row align-items-center gap-2"
                    style={{ backgroundColor: '#DADADA' }}
                    onClick={handleDownload}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                      <IconFileExport className="h-100" />
                    </div>
                    <span style={{ fontWeight: 'bold', margin: 0, cursor: 'pointer', marginLeft: 5, backgroundColor: '#DADADA' }}>Export</span>
                  </button>
                  <button
                    className="btn border border-dark rounded shadow-sm d-flex flex-row align-items-center gap-2"
                    onClick={handleButtonClick}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                      <IconUpload className="h-100" />
                    </div>
                    <span style={{ fontWeight: 'bold', margin: 0, cursor: 'pointer', marginLeft: 5 }}>Upload</span>
                  </button>
                  <input
                    type="file"
                    accept=".xls,.xlsx"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={onFileUpload}
                  />
                </div>

              </div>

              <div style={{
                height: "700px",
                overflowY: "auto",
                overflowX: "hidden",
              }}>
                <table className="table table-bordered" >
                  <thead className="bg-secondary text-white text-center">
                    <tr>
                      <th style={{ width: '30%' }}>Title</th>
                      <th style={{ width: '35%' }}>Pattern</th>
                      <th style={{ width: '20%' }}>Drivers</th>
                      <th style={{ width: '15%' }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      filteredSchedules && filteredSchedules.length > 0 ? filteredSchedules.map((schedule, index) => (
                        <tr key={index}>
                          <td>
                            {schedule.name}
                          </td>
                          <td className="flex-column">
                            {
                              schedule.weeks.map((week, index) => (
                                <div key={index}><strong>{`Week ${week.week_type + 1}: `}</strong>
                                  {week.days.map((day) => {
                                    const dayName = weekDays.find((d) => d.id === day.dayofweek).name || "Unknown";
                                    return <span key={day.dayofweek}>{`${dayName}, `}</span>;
                                  })}
                                </div>
                              ))
                            }
                          </td>
                          <td className="flex-column">
                            {
                              schedule.drivers.map((driver, index) => (
                                <div key={index}>{driver.name}</div>
                              ))
                            }
                          </td>
                          <td className="align-middle">
                            <div
                              className="d-flex flex-column justify-content-center"
                              style={{ height: "100%", paddingLeft: "40%" }}
                            >
                              <button className="btn d-flex align-items-center p-0 mb-2" onClick={() => setScheduleAction('edit', schedule)}>
                                <div style={{ display: "flex", alignItems: "center", height: "0.8rem" }}>
                                  <IconEdit className="h-100" />
                                </div>
                                <span className="ml-1">Edit</span>
                              </button>

                              <button className="btn d-flex align-items-center p-0 mb-2" onClick={() => setScheduleAction('copy', schedule)}>
                                <div style={{ display: "flex", alignItems: "center", height: "0.8rem" }}>
                                  <IconCopyDark className="h-100" />
                                </div>
                                <span className="ml-1">Copy</span>
                              </button>

                              {/* <button className="btn d-flex align-items-center p-0" onClick={() => { setSelectedItem(schedule); setShowDeleteModal(true); }}>
                             <div style={{ display: "flex", alignItems: "center", height: "0.8rem" }}>
                               <IconTrashAlt className="h-100" />
                             </div>
                             <span className="ml-1">Delete</span>
                           </button> */}
                            </div>
                          </td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan="4" className="text-center align-middle">
                            <div>No Data</div>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          ) : (
            <WeeklyView shifts={shifts} stations={stations}/>
          )
          }



        </LoadMask>
      </div>


      <ModalScheduleForm
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        initialValues={selectedItem ? selectedItem : null}
        pilots={availablePilots}
        options={options}
        isCopy={isCopy}
        getSchedules={getAllshcedules}
        resetPilots={getAvailablePilots}
      />

      <ModalDelete
        showModal={showDeleteModal}
        handleCloseModal={() => setShowDeleteModal(false)}
        item={selectedItem}
        title='Delete Schedule'
        text='This will also remove the schedule from
             any driver assigned to it.
             Are you sure you want to continue?'
        deleteType={'schedule'}
      />
    </div>
  )
}


import axios from 'axios';
import moment from 'moment';
import swal from 'sweetalert2';
import { logOut } from '../../redux/modules/login';
const {
    DEFAULT_WINDOW_TIER_1,
    DEFAULT_WINDOW_TIER_2,
    DEFAULT_WINDOW_TIER_3,
    DEFAULT_WINDOW_TIER_1_FO,
    DEFAULT_WINDOW_TIER_2_FO,
    DEFAULT_WINDOW_TIER_3_FO,
} = process.env;

export const errorHandler = (e, defaultMsg = "There was an error", swalOptions = {}) => dispatch => {
    console.log(e);
    if(e.code == 401){
        dispatch(logOut())
    }
    let message = defaultMsg;
    if(typeof(e) == 'string'){
        message = e;
    } else if (typeof(e) == 'object'){
        message = e.message;
    }
    return swal.fire({
        title: "Error",
        text: `${message}`,
        icon: 'error',
        type: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#D50032",
        ...swalOptions,
    });
}

export const shuttleCompanies = [
    {
        liveryId: 12493,
        label: "Lansdowne Resort and Spa",
        code: "LRS",
        street: "44050 Woodridge Pkwy",
        city: "Leesburg",
        state: "VA",
        zip: "20176",
        accountName: "Lansdowne Resort and Spa",
        accountGroup: "Airport Shuttle",
    },
    {
        liveryId: 12492,
        label: "The National Conference Center",
        code: "NCC",
        street: "18980 Upper Belmont Pl",
        city: "Leesburg",
        state: "VA",
        zip: "20176",
        accountName: "NCC",
        accountGroup: "Accounts Payable",
    },
];

export const shuttleAddresses = [
    {
        id: 1,
        companies: [12493],
        label: "Lansdowne Resort and Spa",
        code: "LRS",
        street: "44050 Woodridge Pkwy",
        city: "Leesburg",
        state: "VA",
        zip: "20176",
    },
    {
        id: 2,
        companies: [12492],
        label: "The National Conference Center",
        code: "NCC",
        street: "18980 Upper Belmont Pl",
        city: "Leesburg",
        state: "VA",
        zip: "20176",
    },
    {
        id: 3,
        companies: [12493, 12492],
        label: "Washington Dulles International Airport (IAD)",
        code: "IAD",
        street: "1 Saarinen Cir",
        city: "Sterling",
        state: "VA",
        zip: "20166",
        isAirport: true,
    },
];

// Livery constants
export const liveryBaseUrl = 'https://mobile-abes.liverycoach.com/service.svc/';
export const S_UIUD = "CONNECT_WEB";
export const S_AUTHEN_KEYS = "@123456789AbCdEfGh";

export const stateOptions = [
    { value: "AL", label: "Alabama" },
    { value: "AK", label: "Alaska" },
    { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" },
    { value: "CA", label: "California" },
    { value: "CO", label: "Colorado" },
    { value: "CT", label: "Connecticut" },
    { value: "DC", label: "District of Columbia" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" },
    { value: "GA", label: "Georgia" },
    { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" },
    { value: "IL", label: "Illinois" },
    { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" },
    { value: "KS", label: "Kansas" },
    { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" },
    { value: "ME", label: "Maine" },
    { value: "MD", label: "Maryland" },
    { value: "MA", label: "Massachusetts" },
    { value: "MI", label: "Michigan" },
    { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" },
    { value: "MO", label: "Missouri" },
    { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" },
    { value: "NV", label: "Nevada" },
    { value: "NH", label: "New Hampshire" },
    { value: "NJ", label: "New Jersey" },
    { value: "NM", label: "New Mexico" },
    { value: "NY", label: "New York" },
    { value: "NC", label: "North Carolina" },
    { value: "ND", label: "North Dakota" },
    { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" },
    { value: "OR", label: "Oregon" },
    { value: "PA", label: "Pennsylvania" },
    { value: "RI", label: "Rhode Island" },
    { value: "SC", label: "South Carolina" },
    { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" },
    { value: "TX", label: "Texas" },
    { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" },
    { value: "VA", label: "Virginia" },
    { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" },
    { value: "WI", label: "Wisconsin" },
    { value: "WY", label: "Wyoming" },
];

export const vehicles = [];
export const categories = [];

export const months = [
    {value: "01", label: "01"},
    {value: "02", label: "02"},
    {value: "03", label: "03"},
    {value: "04", label: "04"},
    {value: "05", label: "05"},
    {value: "06", label: "06"},
    {value: "07", label: "07"},
    {value: "08", label: "08"},
    {value: "09", label: "09"},
    {value: "10", label: "10"},
    {value: "11", label: "11"},
    {value: "12", label: "12"},

];

export const years = (() => {
    const ys = []

    const y = moment().format('YY')
    for ( let i = 0; i < 7 ; i++ ) {
        ys.push({
            value: `${ +y + i }`, label: `${ +y + i }`
        })
    }

    return ys
})();

export const hourOptions = [
    { value: "0", label: "0 (12 AM)" },
    { value: "1", label: "1 (1 AM)" },
    { value: "2", label: "2 (2 AM)" },
    { value: "3", label: "3 (3 AM)" },
    { value: "4", label: "4 (4 AM)" },
    { value: "5", label: "5 (5 AM)" },
    { value: "6", label: "6 (6 AM)" },
    { value: "7", label: "7 (7 AM)" },
    { value: "8", label: "8 (8 AM)" },
    { value: "9", label: "9 (9 AM)" },
    { value: "10", label: "10 (10 AM)" },
    { value: "11", label: "11 (11 AM)" },
    { value: "12", label: "12 (12 PM)" },
    { value: "13", label: "13 (1 PM)" },
    { value: "14", label: "14 (2 PM)" },
    { value: "15", label: "15 (3 PM)" },
    { value: "16", label: "16 (4 PM)" },
    { value: "17", label: "17 (5 PM)" },
    { value: "18", label: "18 (6 PM)" },
    { value: "19", label: "19 (7 PM)" },
    { value: "20", label: "20 (8 PM)" },
    { value: "21", label: "21 (9 PM)" },
    { value: "22", label: "22 (10 PM)" },
    { value: "23", label: "23 (11 PM)" },
];

export const hourlyOptions = (() => {
    const hourlyOpts = [];

    for (let i = 2; i < 16; i++) {
        hourlyOpts.push({
            value: `${i}`, label: `${i} Hours`,
        });
    }

    return hourlyOpts;
})();

export const minuteOptions = [
    { value: "0", label: "0" },
    { value: "15", label: "15" },
    { value: "30", label: "30" },
    { value: "45", label: "45" },
];

export const tourCategories = [
    { value: "wine tour", label: "Wine Tour" },
    { value: "brew tour", label: "Brew Tour" },
    { value: "city tour", label: "City Tour" },
    { value: "historical tour", label: "Historical Tour" },
    { value: "educational tour", label: "Educational Tour" },
    { value: "evening city tour", label: "Evening City Tour" },
];

export const getDefaultOverrideHours = (data) => {
    console.log(data);
    const vehicle = data.values.type.trim().toLowerCase();
    const description =
        data.values.description && data.values.description.trim().toLowerCase();

    let WINDOW_OVERRIDE = "";
    if (
        [
            "express limo",
            "express van",
            "executive sedan",
            "executive suv",
            "charter van",
        ].includes(vehicle) ||
        [
            "chevrolet impala or similar",
            "cord transit or similar",
            "cadillac escalade or similar",
            "cord transit or similar",
        ].includes(description)
    ) {
        WINDOW_OVERRIDE = !data.values.inFleet
            ? DEFAULT_WINDOW_TIER_1_FO
            : DEFAULT_WINDOW_TIER_1;
    } else if (
        ["minibus", "midsize bus", "minicoach"].includes(vehicle) ||
        ["commercial bus"].includes(description)
    ) {
        WINDOW_OVERRIDE = !data.values.inFleet
            ? DEFAULT_WINDOW_TIER_2_FO
            : DEFAULT_WINDOW_TIER_2;
    } else if (
        ["coach bus", "elite sedan", "coach", "premier sedan"].includes(vehicle) ||
        ["motorcoach"].includes(description)
    ) {
        WINDOW_OVERRIDE = !data.values.inFleet
            ? DEFAULT_WINDOW_TIER_3_FO
            : DEFAULT_WINDOW_TIER_3;
    }
    return WINDOW_OVERRIDE;
};

export const tourTimes = (() => {
    const times = [];

    for (let i = 1; i < 13; i++) {
        times.push({
            value: i,
            label: i > 1 ? `${i} Hours` : `${i} Hour`,
        });
    }
    return times;
})();

export const pendingTransferColumns = [
    { value: "puAddress", label: "Pick up Address" },
    { value: "doAddress", label: "Drop off Address" },
    { value: "puTimeNoTimezone", label: "Pick up Time" },
    { value: "vehicleType", label: "Vehicle" },
    { value: "liveryNo", label: "Livery No." },
    { value: "duration", label: "Duration" },
    { value: "airline", label: "Airline" },
    { value: "flightNo", label: "Flight No" },
    { value: "nameSign", label: "Name Sign" },
    { value: "driverNote", label: "Driver Note" },
    { value: "promoCode", label: "Promo Code" },
    { value: "guestFirstNm", label: "First Name" },
    { value: "guestLastNm", label: "Last Name" },
    { value: "guestMobile", label: "Mobile" },
    { value: "guestEmail", label: "Email" },
    { value: "createdAt", label: "Booking Date" },
    { value: "transactionAmount", label: "Transaction Amount" },
    { value: "userId", label: "User Id" },
];

export const clientTypes = ["person", "company"];

export const notificationsFor = ["newCompanies"];

export const countryOptions = [
    {label: "United States", value: "US"},
    {label: "Afghanistan", value: "AF"},
    {label: "land Islands", value: "AX"},
    {label: "Albania", value: "AL"},
    {label: "Algeria", value: "DZ"},
    {label: "American Samoa", value: "AS"},
    {label: "AndorrA", value: "AD"},
    {label: "Angola", value: "AO"},
    {label: "Anguilla", value: "AI"},
    {label: "Antarctica", value: "AQ"},
    {label: "Antigua and Barbuda", value: "AG"},
    {label: "Argentina", value: "AR"},
    {label: "Armenia", value: "AM"},
    {label: "Aruba", value: "AW"},
    {label: "Australia", value: "AU"},
    {label: "Austria", value: "AT"},
    {label: "Azerbaijan", value: "AZ"},
    {label: "Bahamas", value: "BS"},
    {label: "Bahrain", value: "BH"},
    {label: "Bangladesh", value: "BD"},
    {label: "Barbados", value: "BB"},
    {label: "Belarus", value: "BY"},
    {label: "Belgium", value: "BE"},
    {label: "Belize", value: "BZ"},
    {label: "Benin", value: "BJ"},
    {label: "Bermuda", value: "BM"},
    {label: "Bhutan", value: "BT"},
    {label: "Bolivia", value: "BO"},
    {label: "Bosnia and Herzegovina", value: "BA"},
    {label: "Botswana", value: "BW"},
    {label: "Bouvet Island", value: "BV"},
    {label: "Brazil", value: "BR"},
    {label: "British Indian Ocean Territory", value: "IO"},
    {label: "Brunei Darussalam", value: "BN"},
    {label: "Bulgaria", value: "BG"},
    {label: "Burkina Faso", value: "BF"},
    {label: "Burundi", value: "BI"},
    {label: "Cambodia", value: "KH"},
    {label: "Cameroon", value: "CM"},
    {label: "Canada", value: "CA"},
    {label: "Cape Verde", value: "CV"},
    {label: "Cayman Islands", value: "KY"},
    {label: "Central African Republic", value: "CF"},
    {label: "Chad", value: "TD"},
    {label: "Chile", value: "CL"},
    {label: "China", value: "CN"},
    {label: "Christmas Island", value: "CX"},
    {label: "Cocos (Keeling) Islands", value: "CC"},
    {label: "Colombia", value: "CO"},
    {label: "Comoros", value: "KM"},
    {label: "Congo", value: "CG"},
    {label: "Congo, The Democratic Republic of the", value: "CD"},
    {label: "Cook Islands", value: "CK"},
    {label: "Costa Rica", value: "CR"},
    {label: "Cote D'Ivoire", value: "CI"},
    {label: "Croatia", value: "HR"},
    {label: "Cuba", value: "CU"},
    {label: "Cyprus", value: "CY"},
    {label: "Czech Republic", value: "CZ"},
    {label: "Denmark", value: "DK"},
    {label: "Djibouti", value: "DJ"},
    {label: "Dominica", value: "DM"},
    {label: "Dominican Republic", value: "DO"},
    {label: "Ecuador", value: "EC"},
    {label: "Egypt", value: "EG"},
    {label: "El Salvador", value: "SV"},
    {label: "Equatorial Guinea", value: "GQ"},
    {label: "Eritrea", value: "ER"},
    {label: "Estonia", value: "EE"},
    {label: "Ethiopia", value: "ET"},
    {label: "Falkland Islands (Malvinas)", value: "FK"},
    {label: "Faroe Islands", value: "FO"},
    {label: "Fiji", value: "FJ"},
    {label: "Finland", value: "FI"},
    {label: "France", value: "FR"},
    {label: "French Guiana", value: "GF"},
    {label: "French Polynesia", value: "PF"},
    {label: "French Southern Territories", value: "TF"},
    {label: "Gabon", value: "GA"},
    {label: "Gambia", value: "GM"},
    {label: "Georgia", value: "GE"},
    {label: "Germany", value: "DE"},
    {label: "Ghana", value: "GH"},
    {label: "Gibraltar", value: "GI"},
    {label: "Greece", value: "GR"},
    {label: "Greenland", value: "GL"},
    {label: "Grenada", value: "GD"},
    {label: "Guadeloupe", value: "GP"},
    {label: "Guam", value: "GU"},
    {label: "Guatemala", value: "GT"},
    {label: "Guernsey", value: "GG"},
    {label: "Guinea", value: "GN"},
    {label: "Guinea-Bissau", value: "GW"},
    {label: "Guyana", value: "GY"},
    {label: "Haiti", value: "HT"},
    {label: "Heard Island and Mcdonald Islands", value: "HM"},
    {label: "Holy See (Vatican City State)", value: "VA"},
    {label: "Honduras", value: "HN"},
    {label: "Hong Kong", value: "HK"},
    {label: "Hungary", value: "HU"},
    {label: "Iceland", value: "IS"},
    {label: "India", value: "IN"},
    {label: "Indonesia", value: "ID"},
    {label: "Iran, Islamic Republic Of", value: "IR"},
    {label: "Iraq", value: "IQ"},
    {label: "Ireland", value: "IE"},
    {label: "Isle of Man", value: "IM"},
    {label: "Israel", value: "IL"},
    {label: "Italy", value: "IT"},
    {label: "Jamaica", value: "JM"},
    {label: "Japan", value: "JP"},
    {label: "Jersey", value: "JE"},
    {label: "Jordan", value: "JO"},
    {label: "Kazakhstan", value: "KZ"},
    {label: "Kenya", value: "KE"},
    {label: "Kiribati", value: "KI"},
    {label: "Korea, Democratic People'S Republic of", value: "KP"},
    {label: "Korea, Republic of", value: "KR"},
    {label: "Kuwait", value: "KW"},
    {label: "Kyrgyzstan", value: "KG"},
    {label: "Lao People'S Democratic Republic", value: "LA"},
    {label: "Latvia", value: "LV"},
    {label: "Lebanon", value: "LB"},
    {label: "Lesotho", value: "LS"},
    {label: "Liberia", value: "LR"},
    {label: "Libyan Arab Jamahiriya", value: "LY"},
    {label: "Liechtenstein", value: "LI"},
    {label: "Lithuania", value: "LT"},
    {label: "Luxembourg", value: "LU"},
    {label: "Macao", value: "MO"},
    {label: "Macedonia, The Former Yugoslav Republic of", value: "MK"},
    {label: "Madagascar", value: "MG"},
    {label: "Malawi", value: "MW"},
    {label: "Malaysia", value: "MY"},
    {label: "Maldives", value: "MV"},
    {label: "Mali", value: "ML"},
    {label: "Malta", value: "MT"},
    {label: "Marshall Islands", value: "MH"},
    {label: "Martinique", value: "MQ"},
    {label: "Mauritania", value: "MR"},
    {label: "Mauritius", value: "MU"},
    {label: "Mayotte", value: "YT"},
    {label: "Mexico", value: "MX"},
    {label: "Micronesia, Federated States of", value: "FM"},
    {label: "Moldova, Republic of", value: "MD"},
    {label: "Monaco", value: "MC"},
    {label: "Mongolia", value: "MN"},
    {label: "Montenegro", value: "ME"},
    {label: "Montserrat", value: "MS"},
    {label: "Morocco", value: "MA"},
    {label: "Mozambique", value: "MZ"},
    {label: "Myanmar", value: "MM"},
    {label: "Namibia", value: "NA"},
    {label: "Nauru", value: "NR"},
    {label: "Nepal", value: "NP"},
    {label: "Netherlands", value: "NL"},
    {label: "Netherlands Antilles", value: "AN"},
    {label: "New Caledonia", value: "NC"},
    {label: "New Zealand", value: "NZ"},
    {label: "Nicaragua", value: "NI"},
    {label: "Niger", value: "NE"},
    {label: "Nigeria", value: "NG"},
    {label: "Niue", value: "NU"},
    {label: "Norfolk Island", value: "NF"},
    {label: "Northern Mariana Islands", value: "MP"},
    {label: "Norway", value: "NO"},
    {label: "Oman", value: "OM"},
    {label: "Pakistan", value: "PK"},
    {label: "Palau", value: "PW"},
    {label: "Palestinian Territory, Occupied", value: "PS"},
    {label: "Panama", value: "PA"},
    {label: "Papua New Guinea", value: "PG"},
    {label: "Paraguay", value: "PY"},
    {label: "Peru", value: "PE"},
    {label: "Philippines", value: "PH"},
    {label: "Pitcairn", value: "PN"},
    {label: "Poland", value: "PL"},
    {label: "Portugal", value: "PT"},
    {label: "Puerto Rico", value: "PR"},
    {label: "Qatar", value: "QA"},
    {label: "Reunion", value: "RE"},
    {label: "Romania", value: "RO"},
    {label: "Russian Federation", value: "RU"},
    {label: "RWANDA", value: "RW"},
    {label: "Saint Helena", value: "SH"},
    {label: "Saint Kitts and Nevis", value: "KN"},
    {label: "Saint Lucia", value: "LC"},
    {label: "Saint Pierre and Miquelon", value: "PM"},
    {label: "Saint Vincent and the Grenadines", value: "VC"},
    {label: "Samoa", value: "WS"},
    {label: "San Marino", value: "SM"},
    {label: "Sao Tome and Principe", value: "ST"},
    {label: "Saudi Arabia", value: "SA"},
    {label: "Senegal", value: "SN"},
    {label: "Serbia", value: "RS"},
    {label: "Seychelles", value: "SC"},
    {label: "Sierra Leone", value: "SL"},
    {label: "Singapore", value: "SG"},
    {label: "Slovakia", value: "SK"},
    {label: "Slovenia", value: "SI"},
    {label: "Solomon Islands", value: "SB"},
    {label: "Somalia", value: "SO"},
    {label: "South Africa", value: "ZA"},
    {label: "South Georgia and the South Sandwich Islands", value: "GS"},
    {label: "Spain", value: "ES"},
    {label: "Sri Lanka", value: "LK"},
    {label: "Sudan", value: "SD"},
    {label: "Suriname", value: "SR"},
    {label: "Svalbard and Jan Mayen", value: "SJ"},
    {label: "Swaziland", value: "SZ"},
    {label: "Sweden", value: "SE"},
    {label: "Switzerland", value: "CH"},
    {label: "Syrian Arab Republic", value: "SY"},
    {label: "Taiwan, Province of China", value: "TW"},
    {label: "Tajikistan", value: "TJ"},
    {label: "Tanzania, United Republic of", value: "TZ"},
    {label: "Thailand", value: "TH"},
    {label: "Timor-Leste", value: "TL"},
    {label: "Togo", value: "TG"},
    {label: "Tokelau", value: "TK"},
    {label: "Tonga", value: "TO"},
    {label: "Trinidad and Tobago", value: "TT"},
    {label: "Tunisia", value: "TN"},
    {label: "Turkey", value: "TR"},
    {label: "Turkmenistan", value: "TM"},
    {label: "Turks and Caicos Islands", value: "TC"},
    {label: "Tuvalu", value: "TV"},
    {label: "Uganda", value: "UG"},
    {label: "Ukraine", value: "UA"},
    {label: "United Arab Emirates", value: "AE"},
    {label: "United Kingdom", value: "GB"},
    {label: "United States Minor Outlying Islands", value: "UM"},
    {label: "Uruguay", value: "UY"},
    {label: "Uzbekistan", value: "UZ"},
    {label: "Vanuatu", value: "VU"},
    {label: "Venezuela", value: "VE"},
    {label: "Viet Nam", value: "VN"},
    {label: "Virgin Islands, British", value: "VG"},
    {label: "Virgin Islands, U.S.", value: "VI"},
    {label: "Wallis and Futuna", value: "WF"},
    {label: "Western Sahara", value: "EH"},
    {label: "Yemen", value: "YE"},
    {label: "Zambia", value: "ZM"},
    {label: "Zimbabwe", value: "ZW"}
]

export const IconOptions = [
    {
        key: 'Available-On-Site-Greeter_Coordinator-(v1)',
        text: 'Available On Site Greeter Coordinator v1',
        value: 'Available-On-Site-Greeter_Coordinator-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Available-On-Site-Greeter_Coordinator-(v1).svg')}` },
    },
    {
        key: 'Available-On-Site-Greeter_Coordinator-(v2)',
        text: 'Available On Site Greeter Coordinator v2',
        value: 'Available-On-Site-Greeter_Coordinator-(v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Available-On-Site-Greeter_Coordinator-(v2).svg')}` },
    },
    {
        key: 'Available-Tour-Guide-(v1)',
        text: 'Available Tour Guide v1',
        value: 'Available-Tour-Guide-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Available-Tour-Guide-(v1).svg')}` },
    },
    {
        key: 'Available-Tour-Guide-(v2)',
        text: 'Available Tour Guide v2',
        value: 'Available-Tour-Guide-(v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Available-Tour-Guide-(v2).svg')}` },
    },
    {
        key: 'Available-USB-Ports_Power-Outlets (v1)',
        text: 'Available USB Ports Power Outlets v1',
        value: 'Available-USB-Ports_Power-Outlets (v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Available-USB-Ports_Power-Outlets (v1).svg')}` },
    },
    {
        key: 'Available-USB-Ports_Power-Outlets-(v2)',
        text: 'Available USB Ports Power Outlets v2',
        value: 'Available-USB-Ports_Power-Outlets-(v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Available-USB-Ports_Power-Outlets-(v2).svg')}` },
    },
    {
        key: 'Available-Wi-Fi-(v1)',
        text: 'Available Wi Fi v1',
        value: 'Available-Wi-Fi-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Available-Wi-Fi-(v1).svg')}` },
    },
    {
        key: 'Complimentary-Bottled-Water-(v1)',
        text: 'Complimentary Bottled Water v1',
        value: 'Complimentary-Bottled-Water-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Complimentary-Bottled-Water-(v1).svg')}` },
    },
    {
        key: 'Connect_App_Icon_white-02',
        text: 'Connect App Icon',
        value: 'Connect_App_Icon_white-02.svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Connect_App_Icon_white-02.svg')}` },
    },
    {
        key: 'Easy-Cancellation-(v1)',
        text: 'Easy Cancellation v1',
        value: 'Easy-Cancellation-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Easy-Cancellation-(v1).svg')}` },
    },
    {
        key: 'Easy-Cancellation-(v2)',
        text: 'Easy Cancellation v2',
        value: 'Easy-Cancellation-(v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Easy-Cancellation-(v2).svg')}` },
    },
    {
        key: 'Emergency-Exits-(v1)',
        text: 'Emergency Exits v1',
        value: 'Emergency-Exits-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Emergency-Exits-(v1).svg')}` },
    },
    {
        key: 'First-Aid-Kit-(v1)',
        text: 'First Aid Kit v1',
        value: 'First-Aid-Kit-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/First-Aid-Kit-(v1).svg')}` },
    },
    {
        key: 'Free-XX-minute-Grace-Period-(v1)',
        text: 'Free XX minute Grace Period v1',
        value: 'Free-XX-minute-Grace-Period-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Free-XX-minute-Grace-Period-(v1).svg')}` },
    },
    {
        key: 'Great-for-Sightseeing-(v1)',
        text: 'Great for Sightseeing v1',
        value: 'Great-for-Sightseeing-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Great-for-Sightseeing-(v1).svg')}` },
    },
    {
        key: 'Great-for-Sightseeing-(v2)',
        text: 'Great for Sightseeing v2',
        value: 'Great-for-Sightseeing-(v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Great-for-Sightseeing-(v2).svg')}` },
    },
    {
        key: 'High-Ceiling (v1)',
        text: 'High Ceiling v1',
        value: 'High-Ceiling (v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/High-Ceiling (v1).svg')}` },
    },
    {
        key: 'High-Ceiling (v2)',
        text: 'High Ceiling v2',
        value: 'High-Ceiling (v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/High-Ceiling (v2).svg')}` },
    },
    {
        key: 'Leather-Interior-(v1)',
        text: 'Leather Interior v1',
        value: 'Leather-Interior-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Leather-Interior-(v1).svg')}` },
    },
    {
        key: 'On-Board-Entertainment-(v1)',
        text: 'On Board Entertainment v1',
        value: 'On-Board-Entertainment-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/On-Board-Entertainment-(v1).svg')}` },
    },
    {
        key: 'On-Board-Entertainment-(v2)',
        text: 'On Board Entertainment v2',
        value: 'On-Board-Entertainment-(v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/On-Board-Entertainment-(v2).svg')}` },
    },
    {
        key: 'On-Board-Luggage-Rack-(v1)',
        text: 'On Board Luggage Rack v1',
        value: 'On-Board-Luggage-Rack-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/On-Board-Luggage-Rack-(v1).svg')}` },
    },
    {
        key: 'On-Board-Luggage-Rack-(v2)',
        text: 'On Board Luggage Rack v2',
        value: 'On-Board-Luggage-Rack-(v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/On-Board-Luggage-Rack-(v2).svg')}` },
    },
    {
        key: 'On-Board-Restroom-(v1)',
        text: 'On Board Restroom v1',
        value: 'On-Board-Restroom-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/On-Board-Restroom-(v1).svg')}` },
    },
    {
        key: 'On-Board-Restroom-(v2)',
        text: 'On Board Restroom v2',
        value: 'On-Board-Restroom-(v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/On-Board-Restroom-(v2).svg')}` },
    },
    {
        key: 'On-Time-Guarantee-(v1)',
        text: 'On Time Guarantee v1',
        value: 'On-Time-Guarantee-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/On-Time-Guarantee-(v1).svg')}` },
    },
    {
        key: 'Panoramic-Roof-(v1)',
        text: 'Panoramic Roof v1',
        value: 'Panoramic-Roof-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Panoramic-Roof-(v1).svg')}` },
    },
    {
        key: 'Panoramic-Roof-(v2)',
        text: 'Panoramic Roof v2',
        value: 'Panoramic-Roof-(v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Panoramic-Roof-(v2).svg')}` },
    },
    {
        key: 'Passenger-Climate-Control-(v1)',
        text: 'EPassenger Climate Control v1',
        value: 'Passenger-Climate-Control-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Passenger-Climate-Control-(v1).svg')}` },
    },
    {
        key: 'Passenger-Climate-Control-(v2)',
        text: 'Passenger Climate Control v2',
        value: 'Passenger-Climate-Control-(v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Passenger-Climate-Control-(v2).svg')}` },
    },
    {
        key: 'Point-Safety-Belts',
        text: 'Point Safety Belts v1',
        value: 'Point-Safety-Belts.svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Point-Safety-Belts.svg')}` },
    },
    {
        key: 'Price-Match-Guarantee-(v1)',
        text: 'Price Match Guarantee v1',
        value: 'Price-Match-Guarantee-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Price-Match-Guarantee-(v1).svg')}` },
    },
    {
        key: 'Privacy-Window-(v2)',
        text: 'Privacy Window v2',
        value: 'Privacy-Window-(v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Privacy-Window-(v2).svg')}` },
    },
    {
        key: 'Reclining-Seats-(v1)',
        text: 'Reclining Seats v1',
        value: 'Reclining-Seats-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Reclining-Seats-(v1).svg')}` },
    },
    {
        key: 'Screened-Driver-(v1)',
        text: 'Screened Driver v1',
        value: 'Screened-Driver-(v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Screened-Driver-(v1).svg')}` },
    },
    {
        key: 'Spacious-Legroom- (v1)',
        text: 'Spacious Legroom v1',
        value: 'Spacious-Legroom- (v1).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Spacious-Legroom- (v1).svg')}` },
    },
    {
        key: 'Spacious-Legroom-(v2)',
        text: 'Spacious Legroom v2',
        value: 'Spacious-Legroom-(v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Spacious-Legroom-(v2).svg')}` },
    },
    {
        key: 'White-Glove-Service1',
        text: 'White Glove Service v1',
        value: 'White-Glove-Service1.svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/White-Glove-Service1.svg')}` },
    },
    {
        key: 'White-Glove-Service',
        text: 'White Glove Service v2',
        value: 'White-Glove-Service.svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/White-Glove-Service.svg')}` },
    },
    {
        key: 'Airport-Greeter-(v2)',
        text: 'Airport Greeter (v2)',
        value: 'Airport-Greeter-(v2).svg',
        image: { avatar: true, src: `${require('../../../assets/img/svg/Airport Greeter (v2).svg')}` }
    },
  ]

export const getDistance = async (origins, destinations) => {
    const request = {
        origins: [origins],
        destinations: [destinations],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
    };
    const service = new google.maps.DistanceMatrixService()

    return service.getDistanceMatrix(request).then(response=>{
        response = response.rows[0].elements[0];
        const formattedNumber = (response.distance.value / 1609);
        return {
            distance: parseFloat(formattedNumber.toFixed(2)),
            duration: response.duration.text
        };
    }).catch(error=>{
        throw error;
    });

}

export const formatAuthorizeTransactionStatus = (status, options) => {
    status = status.trim();
    let newStatus = '';
    if(
        ['approvedReview',
        'authorizedPendingCapture',
        'Authorized/Held Pending Release',
        'FDSAuthorizedPendingReview',
        'settledSuccessfully'].includes(status)
    ){
        newStatus = 'Approved'
    } else if(
        ['underReview',
        'FDSPendingReview'].includes(status)
    ){
        newStatus = 'Pending'
    } else if(['declined'].includes(status)){
        if(!options || options.declinedSwal){
            swal.fire({
                type: 'warning',
                title: 'Transaction declined',
                text: "The customer booked online but the transaction never processed. Please make sure this trip is canceled."
            });
        }
        newStatus = 'Declined';
    } else if(
        ['refundSettledSuccessfully',
        'refundPendingSettlement'].includes(status)
    ){
        newStatus = "Refunded";
    } else if(['voided'].includes(status)){
        newStatus = 'Voided'
    } else {
        newStatus = status;
    }

    // couldNotVoid
    // declined
    // expired

    // failedReview

    // capturedPendingSettlement

    return newStatus;
}

export const airlineRoutesScheduleFrecuency = [
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Bi-Monthly', label: 'Bi-Monthly' },
    { value: 'Quarterly', label: 'Quarterly' },
    { value: 'Semi-Annually', label: 'Semi-Annually' },
    { value: 'Annually', label: 'Annually' },
]

export const TmTtConversion = Tm => {
    if (Tm < 3) return 0.65;
    if (Tm < 6) return 0.7;
    if (Tm < 9) return 0.75;
    if (Tm < 12) return 0.8;
    if (Tm < 15) return 0.85;
    if (Tm < 18) return 0.9;
    if (Tm < 21) return 0.95;
    if (Tm < 24) return 1;
    if (Tm < 27) return 1.143;
    if (Tm < 30) return 1.286;
    if (Tm < 33) return 1.429;
    if (Tm < 36) return 1.572;
    if (Tm < 39) return 1.715;
    if (Tm < 42) return 1.858;
    if (Tm < 45) return 2.001;
    if (Tm < 48) return 2.144;
    if (Tm < 51) return 2.287;
    if (Tm < 54) return 2.430;
    if (Tm < 57) return 2.573;
    if (Tm < 60) return 2.716;
    if (Tm < 65) return 2.859;
    if (Tm < 70) return 3;
    if (Tm < 80) return 75;
    if (Tm < 90) return 85;
    if (Tm < 100) return 95;
    if (Tm < 110) return 105;
    if (Tm < 120) return 115;
    if (Tm < 130) return 125;
    if (Tm < 140) return 135;
    if (Tm < 150) return 145;
    if (Tm < 160) return 155;
    if (Tm < 170) return 165;
    if (Tm < 180) return 175;
    if (Tm < 190) return 185;
    if (Tm < 200) return 195;
    if (Tm < 210) return 205;
    if (Tm < 220) return 215;
    if (Tm < 230) return 225;
    if (Tm < 240) return 235;
    if (Tm < 250) return 245;
    if (Tm < 260) return 255;
    if (Tm < 270) return 265;
    if (Tm < 280) return 275;
    if (Tm < 290) return 285;
    if (Tm < 300) return 295;
    if (Tm < 310) return 305;
    if (Tm < 320) return 315;
    if (Tm < 330) return 325;
    if (Tm < 340) return 335;
    if (Tm < 350) return 345;
    if (Tm < 360) return 355;
    if (Tm < 370) return 365;
    if (Tm < 380) return 375;
    if (Tm < 390) return 385;
    if (Tm < 400) return 395;
    if (Tm < 410) return 405;
    if (Tm < 420) return 415;
    if (Tm < 430) return 425;
    if (Tm < 440) return 435;
    if (Tm < 450) return 445;
    if (Tm < 460) return 455;
    if (Tm < 470) return 465;
    if (Tm < 480) return 475;
    if (Tm < 490) return 485;
    if (Tm < 500) return 495;
    if (Tm < 510) return 505;
    if (Tm < 520) return 515;
    if (Tm < 530) return 525;
    if (Tm < 540) return 535;
    if (Tm < 550) return 545;
    if (Tm < 560) return 555;
    if (Tm < 570) return 565;
    if (Tm < 580) return 575;
    if (Tm < 590) return 585;
    if (Tm < 600) return 595;
    if (Tm < 610) return 605;
    if (Tm < 620) return 615;
    if (Tm < 630) return 625;
    if (Tm < 640) return 635;
    if (Tm < 650) return 645;
    if (Tm < 660) return 655;
    if (Tm < 670) return 665;
    if (Tm < 680) return 675;
    if (Tm < 690) return 685;
    if (Tm < 700) return 695;
    if (Tm < 710) return 705;
    if (Tm < 720) return 715;
    if (Tm < 730) return 725;
    if (Tm < 740) return 735;
    if (Tm < 750) return 745;
    if (Tm < 760) return 755;
    if (Tm < 770) return 765;
    if (Tm < 780) return 775;
    if (Tm < 790) return 785;
    if (Tm < 800) return 795;
    if (Tm < 810) return 805;
    if (Tm < 820) return 815;
    if (Tm < 830) return 825;
    if (Tm < 840) return 835;
    if (Tm < 850) return 845;
    if (Tm < 860) return 855;
    if (Tm < 870) return 865;
    if (Tm < 880) return 875;
    if (Tm < 890) return 885;
    if (Tm < 900) return 895;
    if (Tm < 910) return 905;
    if (Tm < 920) return 915;
    if (Tm < 930) return 925;
    if (Tm < 940) return 935;
    if (Tm < 950) return 945;
    if (Tm < 960) return 955;
    if (Tm < 970) return 965;
    if (Tm < 980) return 975;
    if (Tm < 990) return 985;
    if (Tm < 1000) return 995;
    if (Tm < 1010) return 1005;
    if (Tm < 1020) return 1015;
    if (Tm < 1030) return 1025;
    if (Tm < 1040) return 1035;
    if (Tm < 1050) return 1045;
    if (Tm < 1060) return 1055;
    if (Tm < 1070) return 1065;
    if (Tm < 1080) return 1075;
    if (Tm < 1090) return 1085;
    if (Tm < 1100) return 1095;
    if (Tm < 1110) return 1105;
    if (Tm < 1120) return 1115;
    if (Tm < 1130) return 1125;
    if (Tm < 1140) return 1135;
    if (Tm < 1150) return 1145;
    if (Tm < 1160) return 1155;
    if (Tm < 1170) return 1165;
    if (Tm < 1180) return 1175;
    if (Tm < 1190) return 1185;
    if (Tm < 1200) return 1195;
    if (Tm < 1210) return 1205;
    if (Tm < 1220) return 1215;
    if (Tm < 1230) return 1225;
    if (Tm < 1240) return 1235;
    if (Tm < 1250) return 1245;
    if (Tm < 1260) return 1255;
    if (Tm < 1270) return 1265;
    if (Tm < 1280) return 1275;
    if (Tm < 1290) return 1285;
    if (Tm < 1300) return 1295;
    if (Tm < 1310) return 1305;
    if (Tm < 1320) return 1315;
    if (Tm < 1330) return 1325;
    if (Tm < 1340) return 1335;
    if (Tm < 1350) return 1345;
    if (Tm < 1360) return 1355;
    if (Tm < 1370) return 1365;
    if (Tm < 1380) return 1375;
    if (Tm < 1390) return 1385;
    if (Tm < 1400) return 1395;
    if (Tm < 1410) return 1405;
    if (Tm < 1420) return 1415;
    if (Tm < 1430) return 1425;
    if (Tm < 1440) return 1435;
    if (Tm < 1450) return 1445;
    if (Tm < 1460) return 1455;
    if (Tm < 1470) return 1465;
    if (Tm < 1480) return 1475;
    if (Tm < 1490) return 1485;
    if (Tm < 1500) return 1495;
    if (Tm < 1510) return 1505;
    if (Tm < 1520) return 1515;
    if (Tm < 1530) return 1525;
    if (Tm < 1540) return 1535;
    if (Tm < 1550) return 1545;
    if (Tm < 1560) return 1555;
    if (Tm < 1570) return 1565;
    if (Tm < 1580) return 1575;
    if (Tm < 1590) return 1585;
    if (Tm < 1600) return 1595;
    if (Tm < 1610) return 1605;
    if (Tm < 1620) return 1615;
    if (Tm < 1630) return 1625;
    if (Tm < 1640) return 1635;
    if (Tm < 1650) return 1645;
    if (Tm < 1660) return 1655;
    if (Tm < 1670) return 1665;
    if (Tm < 1680) return 1675;
    if (Tm < 1690) return 1685;
    if (Tm < 1700) return 1695;
    if (Tm < 1710) return 1705;
    if (Tm < 1720) return 1715;
    if (Tm < 1730) return 1725;
    if (Tm < 1740) return 1735;
    if (Tm < 1750) return 1745;
    if (Tm < 1760) return 1755;
    if (Tm < 1770) return 1765;
    if (Tm < 1780) return 1775;
    if (Tm < 1790) return 1785;
    if (Tm < 1800) return 1795;
    if (Tm < 1810) return 1805;
    if (Tm < 1820) return 1815;
    if (Tm < 1830) return 1825;
    if (Tm < 1840) return 1835;
    if (Tm < 1850) return 1845;
    if (Tm < 1860) return 1855;
    if (Tm < 1870) return 1865;
    if (Tm < 1880) return 1875;
    if (Tm < 1890) return 1885;
    if (Tm < 1900) return 1895;
    if (Tm < 1910) return 1905;
    if (Tm < 1920) return 1915;
    if (Tm < 1930) return 1925;
    if (Tm < 1940) return 1935;
    if (Tm < 1950) return 1945;
    if (Tm < 1960) return 1955;
    if (Tm < 1970) return 1965;
    if (Tm < 1980) return 1975;
    if (Tm < 1990) return 1985;
    if (Tm < 2000) return 1995;
    if (Tm < 2010) return 2005;
    if (Tm < 2020) return 2015;
    if (Tm < 2030) return 2025;
    if (Tm < 2040) return 2035;
    if (Tm < 2050) return 2045;
    if (Tm < 2060) return 2055;
    if (Tm < 2070) return 2065;
    if (Tm < 2080) return 2075;
    if (Tm < 2090) return 2085;
    if (Tm < 2100) return 2095;
    if (Tm < 2110) return 2105;
    if (Tm < 2120) return 2115;
    if (Tm < 2130) return 2125;
    if (Tm < 2140) return 2135;
    if (Tm < 2150) return 2145;
    if (Tm < 2160) return 2155;
    if (Tm < 2170) return 2165;
    if (Tm < 2180) return 2175;
    if (Tm < 2190) return 2185;
    if (Tm < 2200) return 2195;
    if (Tm < 2210) return 2205;
    if (Tm < 2220) return 2215;
    if (Tm < 2230) return 2225;
    if (Tm < 2240) return 2235;
    if (Tm < 2250) return 2245;
    if (Tm < 2260) return 2255;
    if (Tm < 2270) return 2265;
    if (Tm < 2280) return 2275;
    if (Tm < 2290) return 2285;
    if (Tm < 2300) return 2295;
    if (Tm < 2310) return 2305;
    if (Tm < 2320) return 2315;
    if (Tm < 2330) return 2325;
    if (Tm < 2340) return 2335;
    if (Tm < 2350) return 2345;
    if (Tm < 2360) return 2355;
    if (Tm < 2370) return 2365;
    if (Tm < 2380) return 2375;
    if (Tm < 2390) return 2385;
    if (Tm < 2400) return 2395;
    if (Tm < 2410) return 2405;
    if (Tm < 2420) return 2415;
    if (Tm < 2430) return 2425;
    if (Tm < 2440) return 2435;
    if (Tm < 2450) return 2445;
    if (Tm < 2460) return 2455;
    if (Tm < 2470) return 2465;
    if (Tm < 2480) return 2475;
    if (Tm < 2490) return 2485;
    if (Tm < 2500) return 2495;
    if (Tm < 2510) return 2505;
    if (Tm < 2520) return 2515;
    if (Tm < 2530) return 2525;
    if (Tm < 2540) return 2535;
    if (Tm < 2550) return 2545;
    if (Tm < 2560) return 2555;
    if (Tm < 2570) return 2565;
    if (Tm < 2580) return 2575;
    if (Tm < 2590) return 2585;
    if (Tm < 2600) return 2595;
    if (Tm < 2610) return 2605;
    if (Tm < 2620) return 2615;
    if (Tm < 2630) return 2625;
    if (Tm < 2640) return 2635;
    if (Tm < 2650) return 2645;
    if (Tm < 2660) return 2655;
    if (Tm < 2670) return 2665;
    if (Tm < 2680) return 2675;
    if (Tm < 2690) return 2685;
    if (Tm < 2700) return 2695;
    if (Tm < 2710) return 2705;
    if (Tm < 2720) return 2715;
    if (Tm < 2730) return 2725;
    if (Tm < 2740) return 2735;
    if (Tm < 2750) return 2745;
    if (Tm < 2760) return 2755;
    if (Tm < 2770) return 2765;
    if (Tm < 2780) return 2775;
    if (Tm < 2790) return 2785;
    if (Tm < 2800) return 2795;
    if (Tm < 2810) return 2805;
    if (Tm < 2820) return 2815;
    if (Tm < 2830) return 2825;
    if (Tm < 2840) return 2835;
    if (Tm < 2850) return 2845;
    if (Tm < 2860) return 2855;
    if (Tm < 2870) return 2865;
    if (Tm < 2880) return 2875;
    if (Tm < 2890) return 2885;
    if (Tm < 2900) return 2895;
    if (Tm < 2910) return 2905;
    if (Tm < 2920) return 2915;
    if (Tm < 2930) return 2925;
    if (Tm < 2940) return 2935;
    if (Tm < 2950) return 2945;
    if (Tm < 2960) return 2955;
    if (Tm < 2970) return 2965;
    if (Tm < 2980) return 2975;
    if (Tm < 2990) return 2985;
    if (Tm < 3000) return 2995;
    if (Tm < 3010) return 3005;
    if (Tm < 3020) return 3015;
    if (Tm < 3030) return 3025;
    if (Tm < 3040) return 3035;
    if (Tm < 3050) return 3045;
    if (Tm < 3060) return 3055;
    if (Tm < 3070) return 3065;
    if (Tm < 3080) return 3075;
    if (Tm < 3090) return 3085;
    if (Tm < 3100) return 3095;
    if (Tm < 3110) return 3105;
    if (Tm < 3120) return 3115;
    if (Tm < 3130) return 3125;
    if (Tm < 3140) return 3135;
    if (Tm < 3150) return 3145;
    if (Tm < 3160) return 3155;
    if (Tm < 3170) return 3165;
    if (Tm < 3180) return 3175;
    if (Tm < 3190) return 3185;
    if (Tm < 3200) return 3195;
    if (Tm < 3210) return 3205;
    if (Tm < 3220) return 3215;
    if (Tm < 3230) return 3225;
    if (Tm < 3240) return 3235;
    if (Tm < 3250) return 3245;
    if (Tm < 3260) return 3255;
    if (Tm < 3270) return 3265;
    if (Tm < 3280) return 3275;
    if (Tm < 3290) return 3285;
    if (Tm < 3300) return 3295;
    if (Tm < 3310) return 3305;
    return 3315;
};

export const liveryVehicleType = [
    { id: 34, label: "Express Sedan", type: "Express Sedan" },
    { id: 33, label: "Premier Sedan (3)", type: "Premier Sedan" },
    { id: 1, label: "Executive Sedan (3)", type: "Executive Sedan" },
    { id: 2, label: "Executive SUV (5)", type: "Executive SUV" },
    { id: 31, label: "Express Limo (5)", type: "Express Limo" },
    { id: 4, label: "Express Van (10)", type: "Express Van" },
    { id: 23, label: "Executive Van (14)", type: "Executive Van" },
    { id: 10, label: "Minibus (20)", type: "Minibus" },
    { id: 26, label: "Stretch Limousine", type: "Stretch Limousine" },
    { id: 5, label: "Midsize Bus (25)", type: "Midsize Bus" },
    { id: 6, label: "Minicoach (35)", type: "Minicoach" },
    { id: 28, label: "Specialty", type: "Specialty" },
    { id: 27, label: "Tour Guide", type: "Tour Guide" },
    { id: 24, label: "Coordinator", type: "Coordinator" },
    { id: 16, label: "Motorcoach (55)", type: "Motorcoach" },
    { id: 32, label: "Cargo Van", type: "Cargo Van" },
]

export const liveryStatus = [
    { key: -1, code: '', description: "Pending" },
    { key: 1, code: 'u', description: "Unassigned" },
    { key: 2, code: 'a', description: "Assigned" },
    { key: 3, code: 'd', description: "Dispatched" },
    { key: 4, code: '1', description: "On The Way" },
    { key: 5, code: '2', description: "Arrived" },
    { key: 6, code: '3', description: "Customer In Car" },
    { key: 7, code: '4', description: "Done" },
    { key: 8, code: 'x', description: "Canceled" },
    { key: 9, code: 'z', description: "Down" },
    { key: 31, code: 'c', description: "Flight Time Change" },
    { key: 32, code: 'f', description: "Farm Out - Unassigned" },
    { key: 35, code: 'n', description: "No Show" },
    { key: 36, code: 'p', description: "Late Cancel" },
    { key: 37, code: 'q', description: "Quote" },
]

/* *************************************** */
/*           DRIVER APPLICATION            */
/* *************************************** */

export const prefixList = [
    { value: "Mr.", label: "Mr." },
    { value: "Ms.", label: "Ms." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Dr.", label: "Dr." },
    { value: "Other", label: "Other" },
]

export const educationList = [
    { value: "high_school", label: "Some High School" },
    { value: "high_school_diploma", label: "Hight School Diploma or GED" },
    { value: "college", label: "Some College" },
    { value: "associate_degree", label: "Associate Degree" },
    { value: "bachelor_degree", label: "Bachelor's Degree" },
    { value: "master_degree", label: "Master's Degree" },
]

export const positionList = [
    { value: "airline", label: "Airline" },
    { value: "limo", label: "Limo" },
    { value: "charter_bus", label: "Charter Bus" },
]

export const availabilityList = [
    { value: "full_time", label: "Full Time" },
    { value: "part_time", label: "Part Time" },
    { value: "on_call", label: "On Call" },
]

export const shiftList = [
    { value: "04_16", label: "Daytime (04:00 - 16:00)" },
    { value: "14_02", label: "Midday (14:00 - 02:00)" },
    { value: "14_10", label: "Midday Short (14:00 - 10:00)-Bus Only" },
    { value: "04_10", label: "Early Morning (04:00 - 10:00)" },
    { value: "10_17", label: "Late Morning (10:00 - 17:00)" },
    { value: "12_18", label: "Afternoon (12:00 - 18:00)" },
    { value: "20_02", label: "Evening (20:00 - 02:00)" },
    { value: "00_04", label: "Midnight (00:00 - 04:00)" },
    { value: "20_08", label: "Overnight (20:00 - 08:00)" },
    { value: "04_18", label: "Daytime Extended (04:00 - 18:00)" },
    { value: "oncall_04_16", label: "On-Call (AM) (04:00 - 16:00)" },
    { value: "oncall_14_02", label: "On-Call (PM) (14:00 - 02:00)" },
    { value: "other", label: "Other" },
]

export const reasonLeavingList = [
    { value: "career_advancement", label: "Career Advancement" },
    { value: "better_benefits", label: "Better Compensation/Benefits" },
    { value: "relocation", label: "Relocation" },
    { value: "family/personal", label: "Family/Personal Reasons" },
    { value: "new_challenges", label: "Seeking New Challenges" },
    { value: "job_dissatisfaction", label: "Job Dissatisfaction" },
    { value: "laid/down", label: "Laid Off/Downsizing" },
    { value: "health", label: "Health Reasons" },
    { value: "terminated", label: "Terminated" },
    { value: "retirement", label: "Retirement" },
]

export const dlTypeList = [
    { value: "non_cdl", label: "Non-CDL" },
    { value: "cdl", label: "CDL" },
]

export const experienceList = [
    { value: "1-3_years", label: "1-3 Years" },
    { value: "4-7_years", label: "4-7 Years" },
    { value: "8-10_years", label: "8-10 Years" },
    { value: "10-more_years", label: "10+ Years" },
]

export const statusList = [
    { label: "Application Submitted", value: "application_submitted", description: "Confirms that the application has been successfully submitted." },
    { label: "Application Incomplete", value: "application_incomplete", description: "Indicates that the application is missing required information or documents." },
    { label: "Application Under Initial Review", value: "application_under_initial_review", description: "The application is being reviewed for basic qualifications and completeness." },
    { label: "Application Under Detailed Review", value: "application_under_detailed_review", description: "The application is being thoroughly reviewed by the hiring team." },
    { label: "Additional Information Requested", value: "additional_information_requested", description: "The hiring team has requested more information or documents from the candidate." },
    { label: "Screening Call Scheduled", value: "screening_call_scheduled", description: "A preliminary phone or video interview has been scheduled." },
    { label: "Screening Call Completed", value: "screening_call_completed", description: "The initial screening call has been completed." },
    { label: "Interview Scheduled", value: "interview_scheduled", description: "A formal interview (or series of interviews) has been scheduled." },
    { label: "Interview Completed", value: "interview_completed", description: "The interview process has been completed." },
    { label: "References Requested", value: "references_requested", description: "The hiring team is in the process of contacting the candidate’s references." },
    { label: "References Verified", value: "references_verified", description: "The candidate’s references have been contacted and verified." },
    { label: "Background Check Initiated", value: "background_check_initiated", description: "A background check has been initiated." },
    { label: "Background Check Completed", value: "background_check_completed", description: "The background check has been completed." },
    { label: "Offer In Progress", value: "offer_in_progress", description: "The hiring team is preparing a job offer for the candidate. " },
    { label: "Offer Extended", value: "offer_extended", description: "A job offer has been made to the candidate." },
    { label: "Offer Accepted", value: "offer_accepted", description: "The candidate has accepted the job offer." },
    { label: "Offer Declined", value: "offer_declined", description: "The candidate has declined the job offer." },
    { label: "Position Filled", value: "position_filled", description: "The position has been filled by another candidate." },
    { label: "Application Rejected", value: "application_rejected", description: "The application was not successful." },
    { label: "Application On Hold", value: "application_on_hold", description: "The application process is temporarily paused." },
    { label: "Transferred to On-boarding", value: "transferred_to_on-boarding", description: "" },
    { label: "Pending Contact", value: "pending_contact", description: "" },
    { label: "Hired", value: "hired", description: "" },
]

import React, { useRef, useState } from 'react'
import { Link } from "react-router-dom";
import CalendarDay from '../../icons/calendar-day';
import CalendarDays from '../../icons/calendar-days';
import CalendarX from '../../icons/calendar-x';
import { settingsDriverSchedulesDailyShifts, settingsDriverSchedulesSchedules, settingsDriverSchedulesTimeOff, settingsDriverSchedulesDrivers } from '../../../routesConfiguration/paths';
import DriverIcon from '../../icons/driverIcon'
import './styles.css';

export const DriverSchedule = () => {

    return (
        <div className="d-flex flex-wrap justify-content-center">
          
            <div className="col-12 col-xl-10 justify-content-center d-flex flex-wrap mt-5" >
                <div className="col-6 col-md-4 col-xl-3 mt-3">
                    <Link to={settingsDriverSchedulesDailyShifts}
                        style={{ borderWidth: '2px', borderStyle: 'solid', borderColor: '#707070', color: '#000', maxWidth:'150px'}}
                        className="rounded shadow text-decoration-none py-3 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                    >
                        <div style={{ height: '2.5rem' }}>
                            <CalendarDay className="h-100" />
                        </div>
                        <span className="mt-3">Daily Shifts</span>
                    </Link>
                </div>
                <div className="col-6 col-md-4 col-xl-3 mt-3">
                    <Link to={settingsDriverSchedulesTimeOff}
                        style={{ borderWidth: '2px', borderStyle: 'solid', borderColor: '#707070', color: '#000', maxWidth:'150px'}}
                        className="rounded shadow text-decoration-none py-3 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                    >
                        <div style={{ height: '2.5rem' }}>
                            <CalendarX className="h-100" />
                        </div>
                        <span className="mt-3">Time Off</span>
                    </Link>
                </div>
                <div className="col-6 col-md-4 col-xl-3 mt-3">
                    <Link to={settingsDriverSchedulesSchedules}
                        style={{ borderWidth: '2px', borderStyle: 'solid', borderColor: '#707070', color: '#000', maxWidth:'150px'}}
                        className="rounded shadow text-decoration-none py-3 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                    >
                        <div style={{ height: '2.5rem' }}>
                            <CalendarDays className="h-100" />
                        </div>
                        <span className="mt-3">Schedules</span>
                    </Link>
                </div>
                <div className="col-6 col-md-4 col-xl-3 mt-3">
                    <Link to={settingsDriverSchedulesDrivers}
                        style={{ borderWidth: '2px', borderStyle: 'solid', borderColor: '#707070', color: '#000', maxWidth:'150px'}}
                        className="rounded shadow text-decoration-none py-3 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                    >
                        <div style={{ height: '2.5rem' }}>
                            <DriverIcon className="h-100" />
                        </div>
                        <span className="mt-3">Drivers</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}



// Home
export const home = "/";
// Reservations
export const reservations = "/reservations";

// Reservations > Airline
export const reservationsAirline = "/reservations/airline";
export const reservationsAirlineBook = "/reservations/airline/book";
export const reservationsAirlineTrackTrip = "/reservations/airline/track-trip";
export const reservationsAirlineTrackTripFlightFlights =
    "/reservations/airline/track-trip/:flight/flights";
export const reservationsAirlineTrackTripFlightFlightsId =
    "/reservations/airline/track-trip/:flight/flights/:id";
export const reservationsAirlineSingleTripSearch =
    "/reservations/airline/single-trip-search";
export const reservationsAirlineSingleTripSearchId =
    "/reservations/airline/single-trip-search/:id";
export const reservationsAirlineMultiTripSearch =
    "/reservations/airline/multi-trip-search";
export const reservationsAirlineMultiTripSearchList =
    "/reservations/airline/multi-trip-search/list";
export const reservationsAirlineUploads = "/reservations/airline/uploads";
export const reservationsAirlineUploadsId = "/reservations/airline/uploads/:id";

// Reservations > Charter
export const reservationsCharter = "/reservations/charter";
export const reservationsCharterQuotes = "/reservations/charter/quotes";
export const reservationsCharterQuotesNew = "/reservations/charter/quotes/New";
export const reservationsCharterQuotesId = "/reservations/charter/quotes/:id";

export const reservationsCharterBookings = "/reservations/charter/bookings";
export const reservationsCharterBookingsId =
    "/reservations/charter/bookings/:id";
export const reservationsCharterBookingsDetailId =
    "/reservations/charter/bookings/detail/:id";
export const reservationsCharterBookingsEditTripId =
    "/reservations/charter/bookings/edit-trip/:id";

export const reservationsCharterReviews = "/reservations/charter/reviews";

export const reservationsCharterReviewsPassenger =
    "/reservations/charter/reviews/passenger";
export const reservationsCharterReviewsPassengerId =
    "/reservations/charter/reviews/passenger/:id";

export const reservationsCharterReviewsBooking =
    "/reservations/charter/reviews/booking";
export const reservationsCharterReviewsBookingId =
    "/reservations/charter/reviews/booking/:id";

export const reservationsCharterReviewsService =
    "/reservations/charter/reviews/service";
export const reservationsCharterReviewsServiceId =
    "/reservations/charter/reviews/service/:id";

export const reservationsCharterReviewsId =
    "/reservations/charter/reviews/:type/:id";

export const reservationsCharterAirportMeetingProcedures =
    "/reservations/charter/airport-meeting-procedures";

// Reservations > Tours
export const reservationsTours = "/reservations/tours";
export const reservationsToursId = "/reservations/tours/:id";

// Reservations > Shuttles
export const reservationsShuttles = "/reservations/shuttles";
export const reservationsShuttlesTracking = "/reservations/shuttles/tracking";
export const reservationsShuttlesTrackingId =
    "/reservations/shuttles/tracking/:id";
export const reservationsShuttlesTrackingNew =
    "/reservations/shuttles/tracking/new";
    
// Reservations > Expense Tickets
export const resrvationsExpenseTickets = "/reservations/expense-tickets"

// Reports
export const reports = "/reports";

// Reports > Revenue
export const reportsRevenue = "/reports/revenue";

// Reports > Trip Report
export const reportsTripReport = "/reports/trip-report";
export const reportsTripReportId = "/reports/trip-report/:id";
export const hotelPickupReport = "/reports/trip-report/hotel-Pickup";

// Marketing
export const marketing = "/marketing";

// Marketing > Promo Codes
export const marketingPromoCodes = "/marketing/promo-codes";
export const marketingPromoCodesId = "/marketing/promo-codes/:id";
export const marketingPromoCodesNew = "/marketing/promo-codes/new";

// Marketing > Blog posts
export const marketingBlogPosts = "/marketing/blog-posts";
export const marketingBlogPostsNew = "/marketing/blog-posts/new";
export const marketingBlogPostsId = "/marketing/blog-posts/:id";

//Hr > Applications
export const hrApplications = "/hr/applications";
export const hrApplicationsId = "/hr/applications/:id";

// Settings
export const settings = "/settings";

// Settings > Tours
export const settingsTours = "/settings/tours";
export const settingsToursCreate = "/settings/tours/create";
export const settingsToursId = "/settings/tours/:id";

// Settings > Accounts (Customers)
export const settingsAccounts = "/settings/accounts";
export const settingsAccountsCompanies = "/settings/accounts/companies";
export const settingsAccountsCompaniesId = "/settings/accounts/companies/:id";
export const settingsAccountsCustomers = "/settings/accounts/customers";
export const settingsAccountsCustomersNew = "/settings/accounts/customers/new";
export const settingsAccountsCustomersId = "/settings/accounts/customers/:id";
export const settingsAccountsPoints = "/settings/accounts/points";
export const settingsAccountsPointsId = "/settings/accounts/points/:id";

// Settings > Rates
export const settingsRates = "/settings/rates";
export const settingsRatesLocations = "/settings/rates/locations";
export const settingsRatesLocationsCreate = "/settings/rates/locations/create";
export const settingsRatesLocationsId = "/settings/rates/locations/:id";
export const settingsRatesLocationsIdUpdate =
    "/settings/rates/locations/:id/update";
export const settingsRatesLocationsIdVehicles =
    "/settings/rates/locations/:id/vehicles";
export const settingsRatesLocationsIdZipCodes =
    "/settings/rates/locations/:id/zip-codes";
export const settingsRatesLocationsIdPromotionalRates =
    "/settings/rates/locations/:id/promotional-rates";
export const settingsRatesLocationsIdPromotionalRatesSpecialRates =
    "/settings/rates/locations/:id/promotional-rates/special-rates";
export const settingsRatesLocationsIdPromotionalRatesPointToPoint =
    "/settings/rates/locations/:id/promotional-rates/point-to-point";
export const settingsRatesLocationsIdPromotionalRatesPromoCodes =
    "/settings/rates/locations/:id/promotional-rates/promo-codes";
export const settingsRatesVehicles = "/settings/rates/vehicles";
export const settingsRatesVehiclesId = "/settings/rates/vehicles/:id";
export const settingsRatesVehiclesNew = "/settings/rates/vehicles/new";
export const settingsRatesFees = "/settings/rates/fees";
export const settingsRatesFeesId = "/settings/rates/fees/:id";
export const settingsRatesFeesNew = "/settings/rates/fees/new";

// Settings > Airline (Airline Crew)
export const settingsAirlines = "/settings/airlines";
export const settingsAirlinesAccounts = "/settings/airlines/accounts";
export const settingsAirlinesAccountsId = "/settings/airlines/accounts/:id";

export const settingsAirlinesAccountsIdRouteNew =
    "/settings/airlines/accounts/:airlineId/route/new";

export const settingsAirlinesAccountsIdRouteIdUpdate =
    "/settings/airlines/accounts/:airlineId/route/:id/update";

export const settingsAirlinesAccountsIdRouteId =
    "/settings/airlines/accounts/:airlineId/route/:id";

export const settingsAirlinesAccountsIdAccountGroupsNew =
    "/settings/airlines/accounts/:airlineId/account-groups/new";

export const settingsAirlinesAccountsIdAccountGroupsId =
    "/settings/airlines/accounts/:airlineId/account-groups/:id";

export const settingsAirlinesZones = "/settings/airlines/zones";
export const settingsAirlinesZonesId = "/settings/airlines/zones/:id";
export const settingsAirlinesZonesNew = "/settings/airlines/zones/new";
export const settingsAirlinesAirports = "/settings/airlines/airports";
export const settingsAirlinesContacts = "/settings/airlines/contacts";
export const settingsAirlinesLocations = "/settings/airlines/locations";
export const settingsAirlinesOwners = "/settings/airlines/owners";

// Settings > User Accounts
export const settingsUserAccounts = "/settings/user-accounts";

export const settingsUserAccountsUsers = "/settings/user-accounts/users";
export const settingsUserAccountsUsersNew = "/settings/user-accounts/users/new";
export const settingsUserAccountsUsersId = "/settings/user-accounts/users/:id";

export const settingsUserAccountsPermissions =
    "/settings/user-accounts/permissions";
export const settingsUserAccountsPermissionsNew =
    "/settings/user-accounts/permissions/new";
export const settingsUserAccountsPermissionsId =
    "/settings/user-accounts/permissions/:id";

// HR > Driver Schedules
export const settingsDriverSchedules = "/hr/driver-schedules"
export const settingsDriverSchedulesDailyShifts = "/hr/driver-schedules/daily-shifts"
export const settingsDriverSchedulesTimeOff = "/hr/driver-schedules/time-off"
export const settingsDriverSchedulesSchedules = "/hr/driver-schedules/schedules"
export const settingsDriverSchedulesDrivers = "/hr/driver-schedules/drivers"

// No modified routes
export const refundId = "/refund/:id";
export const priceCheck = "/reservations/airline/price-check";
export const shuttlesId = "/shuttles/:id";
export const liveryShuttles = "/reservations/shuttles/livery-shuttles";
export const liveryShuttlesCompany =
    "/reservations/shuttles/livery-shuttles/:company";
export const airlineLivery = "/reservations/airline/multi-trip-search";
export const airlineLiveryDetails =
    "/reservations/airline/multi-trip-search/list";

export const login = "/login";
export const passwordReset = "/password_reset";
export const passwordResetToken = "/password_reset/:token";
export const successToken = "/success/:token";

export const getPathWithParams = (pathBase, parameterName, value) => {
    const param = `:${parameterName}`;
    const fullPath = pathBase.replace(param, value);
    return fullPath;
};

export const getFullPathWithParams = (pathBase, params = [], values = []) => {
    const fullPath = params.reduce(
        (path, current, index) =>
            getPathWithParams(path, current, values[index]),
        pathBase
    );

    return fullPath;
};

import React from 'react'
import { Grid, Paper, makeStyles } from "@material-ui/core";
import ArrowRightIcon from '../../../../icons/arrow-right';

const useStyles = makeStyles((theme) => ({
    cellOverlapShiftContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%", 
        width: "100%",
        padding: 0,
        borderTop: "0.2px solid #f5f0f0",
        borderBottom: 'none'
    },
    shiftStart: {
        fontSize: 12,
        padding: 5,
        paddingRight: 0,
        margin: 0,
        width: "50%",
        textAlign: "right",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center",
        gap: "4px",
        boxShadow: 'none'
    },
}));

export const FinalCellHourly = ({ id, color, from }) => {
    const classes = useStyles();
    const formatHour = (hour) => String(hour).padStart(2, '0') + ":00";
    return (
        <Grid item xs key={id} style={{ flexGrow: 1, display:'flex', justifyContent:'flex-end'}}>
            <div className={classes.cellOverlapShiftContainer}
                style={{
                    borderTop: "0.2px solid #f5f0f0",
                    borderRight: "0.2px solid #f5f0f0"
                }}
            >
                <Paper
                    className={classes.shiftStart}
                    style={{borderLeft:"1px solid #f5f0f0", borderTop:"1px solid #f5f0f0", borderBottom:"1px solid #f5f0f0", borderRight:"none", color:'white', backgroundColor:'#FF9AB2'}}
                >
                    {formatHour(from)} <ArrowRightIcon size={12} /> 
                </Paper>
            </div>
        </Grid>
    )
}
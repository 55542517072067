import React, { useState } from 'react'
import ModalTimeOffRequest from '../ModalTimeOffRequest';
import './styles.css';

export const DriversList = ({ requests, typeOptions, getLeaveRequests, handleRequest }) => {

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null)
  const [search, setSearch] = useState("");
  const [filters, setFilters] = React.useState({
    month: '',
    status: '',
    type: '',
  });

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const monthOptions = [
    { label: 'January', value: '01' },
    { label: 'February', value: '02' },
    { label: 'March', value: '03' },
    { label: 'April', value: '04' },
    { label: 'May', value: '05' },
    { label: 'June', value: '06' },
    { label: 'July', value: '07' },
    { label: 'August', value: '08' },
    { label: 'September', value: '09' },
    { label: 'October', value: '10' },
    { label: 'November', value: '11' },
    { label: 'December', value: '12' }
  ];

  const statusOptions = [
    { label: 'Pending', value: 'sent' },
    { label: 'Approved', value: 'approved' },
    { label: 'Denied', value: 'denied' },
    { label: 'Canceled', value: 'canceled' }
  ];

  const handleClick = (leave) => {
    setShowModal(true);
    setSelectedItem(leave)
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "sent":
        return { text: "Pending", backgroundColor: "#ffc107" };
      case "approved":
        return { text: "Approved", backgroundColor: "#28A745" };
      case "denied":
        return { text: "Denied", backgroundColor: "#DC3545" };
      case "canceled":
        return { text: "Canceled", backgroundColor: "#DC3545" };
      default:
        return { text: "Unknown", backgroundColor: "white" };
    }
  };

  const filtersConfig = [
    { id: 'select1', key: 'month', label: 'Select Month...', options: monthOptions },
    { id: 'select2', key: 'status', label: 'Select Status...', options: statusOptions },
    { id: 'select3', key: 'type', label: 'Select Type...', options: typeOptions },
  ];

  const filteredData = requests && requests.filter((item) => {
    const itemMonth = item.startDate.split('-')[1];
    const matchesSearch = item.name.toLowerCase().includes(search.toLowerCase())
    return (
      (!filters.month || itemMonth === filters.month) &&
      (!filters.status || item.status === filters.status) &&
      (!filters.type || item.type === filters.type) && matchesSearch
    );
  });

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className='align-items-center search-input'>
          <div className='mb-2'>
            <input
              placeholder="Search..."
              className="form-control"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>


          <div className='filter-group' >
            {
              filtersConfig.map(({ id, key, label, options }) => (
                <select
                  key={id}
                  id={id}
                  className="form-control"
                  value={filters[key]}
                  onChange={(e) => handleFilterChange(key, e.target.value)}
                >
                  <option value="">
                    {label}
                  </option>
                  {options.map((opt, index) => (
                    <option key={index} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </select>
              ))
            }
          </div>
        </div>
      </div>

      {/* Table */}
      <div className='my-table'>
        <table className="table" >
          <thead className="text-black font-bold text-left">
            <tr>
              <th style={{ width: '20%' }}>Name</th>
              <th style={{ width: '20%' }}>Summary</th>
              <th style={{ width: '8%' }}>Status</th>
              <th style={{ width: '15%' }}>Approved On</th>
              <th style={{ width: '15%' }}>Approved By</th>
              <th style={{ width: '10%' }}>Type</th>
              <th style={{ width: '12%' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              filteredData ? filteredData.map(request => {
                const { text, backgroundColor } = getStatusStyle(request.status);
                return (
                  <tr key={request.id}>
                    <td>
                      {request.name}
                    </td>
                    <td >
                      {`${request.startDate} - ${request.endDate}`}
                      <div style={{ fontSize: 12, color: '#666' }}>{request.duration} {request.duration !== 1 ? 'days' : 'day'}</div>
                    </td>
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <div style={{ backgroundColor: backgroundColor, padding: 5, color: 'white', borderRadius: 3, fontSize: 12 }}>
                          {text}
                        </div>
                      </div>
                    </td>
                    <td>
                      <span>{request.status === 'approved' ? request.dateRequested : '--'}</span>
                    </td>
                    <td>
                      {request.status === 'approved' ?
                        request.latestUser.includes(".")
                          ? request.latestUser
                          : `${request.latestUser.split(" ")[0][0].toUpperCase()}. ${request.latestUser.split(" ")[1].charAt(0).toUpperCase() + request.latestUser.split(" ")[1].slice(1)}`
                        : '--'
                      }
                    </td>
                    <td className='justify-items-center'>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 5 }} onClick={() => handleClick(request)}>
                        <div style={{
                          padding: 5,
                          color: 'white',
                          borderRadius: 3,
                          fontSize: 12,
                          backgroundColor: request.type === 'Personal' ? '#0d6efd'
                            : request.type === 'Vacation' ? '#198754'
                              : request.type === 'Sick' ? '##6c757d'
                                : '',
                        }}>
                          {request.type}
                        </div>
                      </div>
                    </td>
                    <td >
                      <div className='action-group'>
                        <button className="action-button" onClick={() => handleClick(request)}>✏️</button>
                        {
                          request && request.status === 'sent' && (
                            <React.Fragment>
                              <button className="action-button" onClick={() => handleRequest('approved', request.id)}>✅</button>
                              <button className="action-button" onClick={() => handleRequest('denied', request.id)}>❌</button>
                            </React.Fragment>
                          )
                        }
                      </div>

                    </td>
                  </tr>
                )
              }

              ) : (
                <tr>
                  <td colSpan="5" className="text-center align-middle">
                    <div>No Data</div>
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>

      <div className="mobile-cards">
        {
          filteredData ? filteredData.map((request, index) => {
            const { text, backgroundColor } = getStatusStyle(request.status);
            return (
              <div className="my-card" key={index}>
                <div className="my-card-header">
                  <div className="card-name">{request.name}</div>
                  <span className="my-badge" style={{ backgroundColor: backgroundColor }}>{text}</span>
                </div>
                <div className="card-content">
                  <div className="card-field">
                    <div className="card-label">Date Range</div>
                    <div className="card-value">{`${request.startDate} - ${request.endDate}`}</div>
                  </div>
                  <div className="card-field">
                    <div className="card-label">Duration</div>
                    <div className="card-value">{request.duration} {request.duration !== 1 ? 'days' : 'day'}</div>
                  </div>
                  <div className="card-field">
                    <div className="card-label">Type</div>
                    <div className="card-value"><span className="my-badge" style={{
                       backgroundColor: request.type === 'Personal' ? '#0d6efd'
                       : request.type === 'Vacation' ? '#198754'
                         : request.type === 'Sick' ? '##6c757d'
                           : '',
                    }}>{request.type}</span></div>
                  </div>
                  <div className="card-field">
                    <div className="card-label">Approved On</div>
                    <div className="card-value">{request.status === 'approved' ? request.dateRequested : '--'}</div>
                  </div>
                </div>
                <div className="my-card-footer">
                  <div className="card-field">
                    <div className="card-label">Approved By</div>
                    <div className="card-value"> {request.status === 'approved' ?
                        request.latestUser.includes(".")
                          ? request.latestUser
                          : `${request.latestUser.split(" ")[0][0].toUpperCase()}. ${request.latestUser.split(" ")[1].charAt(0).toUpperCase() + request.latestUser.split(" ")[1].slice(1)}`
                        : '--'
                      }</div>
                  </div>
                  <div className='action-group'>
                    <button className="action-button" onClick={() => handleClick(request)}>✏️</button>
                    {
                      request && request.status === 'sent' && (
                        <React.Fragment>
                          <button className="action-button" onClick={() => handleRequest('approved', request.id)}>✅</button>
                          <button className="action-button" onClick={() => handleRequest('denied', request.id)}>❌</button>
                        </React.Fragment>
                      )
                    }
                  </div>
                </div>
              </div>
            )
          }

          ) : (

            <div>No Data</div>

          )
        }

      </div>

      <ModalTimeOffRequest
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        request={selectedItem}
        getStatusStyle={getStatusStyle}
        getLeaves={getLeaveRequests}
      />
    </div>
  )
}

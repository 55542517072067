import React, { useEffect, useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import IconFilterAlt from '../../../icons/filterIconAlt'
import LoadMask from '../../Utils/LoadMask'
import './styles.css';
import ModalTimeOffRequest from '../components/ModalTimeOffRequest';
import { apiDriverApp } from '../../../../libs/apiDriverApp';
import moment from 'moment';
import { DriversList } from '../components/time-off/DriversList';
import Calendar from '../components/time-off/Calendar';
import { StatisticsBoard } from '../components/time-off/StatisticsBoard';
import { connect } from 'react-redux';
import ModalNewTimeOffRequest from '../components/ModalNewTimeOffRequest';
import {DownloadModal} from '../components/time-off/components/DownloadModal'
import IconDownload from '../../../icons/download';

const TimeOff = ({ user }) => {

  const [showModal, setShowModal] = useState(false);
  const [showDlModal, setShowDlModal] = useState(false);
  const [requests, setRequests] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [typeOptions, setTypeOptions] = useState([])
  const [drivers, setDrivers] = useState([])
  const [view, setView] = useState("list");
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getTotalDays = (from_date, to_date) => {
    const fromDate = new Date(from_date);
    const toDate = new Date(to_date);
    const diffTime = toDate - fromDate;
    const totalDays = diffTime / (1000 * 60 * 60 * 24) + 1;
    return totalDays
  }

  const getLeaveRequests = async () => {
    try {
      setIsLoading(true)
      const today = moment().format("YYYY-MM-DD");
      const { data } = await apiDriverApp.get('api/hr/leave/');
      const formattedRequests = data
        .map(item => ({
          id: item.id,
          name: `${item.employee.user.first_name} ${item.employee.user.last_name} (${item.employee.user.username})`,
          startDate: item.from_date.split('T')[0],
          endDate: item.to_date.split('T')[0],
          status: item.status,
          type: item.leave_type.name,
          dateRequested: item.latest_status.date_changed.split('T')[0],
          latestUser: item.latest_status.changed_by || '',
          duration: getTotalDays(item.from_date, item.to_date)
        })).filter(item => moment(item.endDate, "YYYY-MM-DD").isSameOrAfter(today))
        .sort((a, b) => {
          if (a.status === "sent" && b.status !== "sent") return -1;
          if (a.status !== "sent" && b.status === "sent") return 1;
          return moment(a.startDate, "YYYY-MM-DD").diff(moment(b.startDate, "YYYY-MM-DD"));
        });
      setRequests(formattedRequests);
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  };

  const getLeaveTypes = async () => {
    try {
      const { data } = await apiDriverApp.get('/api/hr/leave-types/')
      const typeOptions = data.map(type => ({
        label: type.name,
        value: type.name,
        id: type.id
      }))
      setTypeOptions(typeOptions);
    } catch (error) {
      console.log(error)
    }
  }

  const getDrivers = async () => {
    try {
      const { data } = await apiDriverApp.get('api/hr/employees/');
      const optionDrivers = data.map(driver => ({
        label: `${driver.user.first_name} ${driver.user.last_name}` || '',
        value: `${driver.user.first_name} ${driver.user.last_name}` || '',
        id: driver.id
      }))
      setDrivers(optionDrivers)
    } catch (error) {
      console.log(error)
    }
  }

  const handleRequest = async (action, request) => {
    try {
      const data = await apiDriverApp.post(`api/hr/leave/${request}/update_status/`, {

        status: action,
        modified_by: `${user.firstName.charAt(0).toUpperCase()}. ${user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)}`

      });
      if (data.status == 200) {
        emailService.create(
          {
            from: {
              email: "driverapp-timeoff@connectbbc.com",
            },
            personalizations: [{
              to: [{ email: 'drivers@connectbbc.com' }],
              dynamic_template_data: {
                subject: `Updated ${request.type} Time-Off Request`,
                title: `Update time-off request for ${request.name}`,
                message: `Time-off request for ${request.name} from ${request.startDate} through ${request.endDate} has been updated to ${data.data.new_status} by ${user.firstName} ${user.lastName}.`,
                email_link: 'https://portal.connectbbc.com/#/settings/driver-schedules/time-off',
                email_link_text: 'Go to dashboard'
              }
            }],
            template_id: 'd-50889e22ce0041d8b5255f99a900d35f',
          }
        )
      }
      getLeaveRequests()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getLeaveRequests(),
      getLeaveTypes();
    getDrivers();
  }, [])

  return (
    <div className='custom-container'>
      <div className='button-bar'>
        <h2 className='text-left font-bold'>Time Off Requests</h2>
        <div className="download-upload-buttons">
          <button className='btn-hover' onClick={()=>setShowDlModal(true)}>
            <div className='row'>
              <div style={{ height: '1rem' }}>
                <IconDownload className="h-100" />
              </div>
              <div style={{ marginLeft: 5, fontSize:14 }}>Download Time-off Data</div>
            </div>
          </button>
        </div>
      </div>


      <div className="d-flex align-items-center" style={{ gap: 15 }}>
        <button
          className="btn border border-dark rounded shadow-sm d-flex flex-row align-items-center gap-2"
          style={{ backgroundColor: view === 'list' ? '#DADADA' : 'white' }}
          onClick={() => setView("list")}
        >
          <span style={{ fontWeight: 'bold', margin: 0, cursor: 'pointer', marginLeft: 5 }}>List</span>
        </button>
        <button
          className="btn border border-dark rounded shadow-sm d-flex flex-row align-items-center gap-2"
          style={{ backgroundColor: view === 'calendar' ? '#DADADA' : 'white' }}
          onClick={() => setView("calendar")}
        >
          <span style={{ fontWeight: 'bold', margin: 0, cursor: 'pointer', marginLeft: 5 }}>Calendar</span>
        </button>
        <button
          className="btn border border-dark rounded shadow-sm d-flex flex-row align-items-center gap-2"
          style={{ backgroundColor: view === 'stats' ? '#DADADA' : 'white' }}
          onClick={() => setView("stats")}
        >
          <span style={{ fontWeight: 'bold', margin: 0, cursor: 'pointer', marginLeft: 5 }}>Statistics</span>
        </button>

      </div>
      <div className='mt-3 mb-3'>
        <button
          className="btn rounded shadow-sm d-flex flex-row align-items-center gap-2"
          style={{ backgroundColor: '#D50032' }}
          onClick={() => setShowModal(true)}
        >
          <span style={{ fontWeight: 'bold', margin: 0, cursor: 'pointer', marginLeft: 5, color: 'white', backgroundColor: '#D50032' }}>New Request</span>
        </button>
      </div>

      <LoadMask loading={isLoading} light>
        {view === "list" && (
          <DriversList
            requests={requests}
            typeOptions={typeOptions}
            getLeaveRequests={getLeaveRequests}
            handleRequest={handleRequest}
          />
        )}
      </LoadMask>

      {view === "calendar" && <Calendar />}

      {view === "stats" && <StatisticsBoard />}

      <ModalNewTimeOffRequest
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        typeOptions={typeOptions}
        drivers={drivers}
        getLeaveRequests={getLeaveRequests}
      />

      <DownloadModal
        showModal={showDlModal}
        handleCloseModal={()=>setShowDlModal(false)}
        getTotalDays={getTotalDays}
      />

    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.login.userInfo,
});

export default connect(mapStateToProps)(TimeOff);
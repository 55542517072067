import * as XLSX from 'xlsx';
import { apiDriverApp } from '../../../../libs/apiDriverApp';

const processSheet1 = (rows) => {

    const headers = rows[1];
    const weekColumns = [];

    // Identificar las columnas de cada semana
    headers.forEach((header, index) => {
        if (header && header.toLowerCase().includes('week')) {
            weekColumns.push({ weekName: header, startIndex: index });
        }
    });

    // Crear un arreglo de objetos basados en las filas
    const jsonResult = rows.slice(1).map((row) => {
        const calendarName = row[0];
        const startDateNumeric = row[1];
        const startDate = XLSX.SSF.parse_date_code(startDateNumeric);
        const formattedStartDate = `${startDate.y}-${String(startDate.m).padStart(2, '0')}-${String(startDate.d).padStart(2, '0')}`;
        const numberOfWeeks = row[2];
        const duration = row[3];

        // Procesar semanas dinámicamente
        const weeks = weekColumns.map(({ weekName, startIndex }) => {
            return {
                saturday: row[startIndex] ? [row[startIndex]] : [],
                sunday: row[startIndex + 1] ? [row[startIndex + 1]] : [],
                monday: row[startIndex + 2] ? [row[startIndex + 2]] : [],
                tuesday: row[startIndex + 3] ? [row[startIndex + 3]] : [],
                wednesday: row[startIndex + 4] ? [row[startIndex + 4]] : [],
                thursday: row[startIndex + 5] ? [row[startIndex + 5]] : [],
                friday: row[startIndex + 6] ? [row[startIndex + 6]] : [],
            };
        });

        return {
            calendar_name: calendarName,
            start_date: formattedStartDate,
            number_of_weeks: numberOfWeeks,
            duration: duration,
            weeks: weeks.filter((week) => Object.values(week).some((day) => day.length > 0)), // Filtrar semanas vacías
        };
    });
    jsonResult.splice(0, 2);
    // console.log('Final JSON:', JSON.stringify(jsonResult, null, 2));
    return jsonResult;
};

const processSheet2 = (rows) => {
    return rows.slice(1).map((row) => {
        const title = row[0];
        const time = row[1];

        if (time) {
            const [startTime, endTime] = time.split(' - ').map((t) => t.trim());
            return {
                name: title,
                hour_from: parseInt(startTime.split(':')[0], 10),
                hour_to: parseInt(endTime.split(':')[0], 10),
            };
        }

        return { title };
    }).filter((obj) => obj.name && obj.hour_from !== undefined && obj.hour_to !== undefined);
};


const processSheet3 = (rows) => {
    const processedRows = rows.slice(1).map((row, index) => {
        return {
            AutoScheduleTitle: row[0] || '',
            Employee: row[1],
            FirstName: row[2],
            LastName: row[3],
            Email: row[4] || '',
            MobilePhone: row[5] || '',
            Job: row[6] || '',
            pin_code: row[7] || '',
            device:row[8] || ''
        };
    });
    return processedRows;
};



export const handleFileChange = (file) => {
    return new Promise((resolve, reject) => {
        if (!file) {
            reject('No file provided');
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetOrder = ['Schedules', 'Employees', 'Shifts'];

                const results = sheetOrder.reduce((acc, sheetName) => {
                    if (!workbook.SheetNames.includes(sheetName)) {
                        console.warn(`Sheet "${sheetName}" does not exists on file.`);
                        acc[sheetName] = [];
                        return acc;
                    }

                    const sheet = workbook.Sheets[sheetName];
                    const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    switch (sheetName) {
                        case 'Schedules':
                            acc[sheetName] = processSheet1(rows);
                            break;
                        case 'Shifts':
                            acc[sheetName] = processSheet2(rows);
                            break;
                        case 'Employees':
                            acc[sheetName] = processSheet3(rows);
                            break;
                        default:
                            console.warn(`No logic for sheet "${sheetName}"`);
                    }

                    return acc;
                }, {});

                resolve(results);
            } catch (error) {
                reject(error);
            }
        };

        reader.readAsArrayBuffer(file);
    });
};

export const processShifts = async (dbData, uploadedData) => {
    const dbMap = new Map(dbData.map(item => [item.name, item]));

    const requests = uploadedData.map(item => {
        const match = dbMap.get(item.name);

        if (match) {
            // console.log(`Actualizando shift con id ${match.id}`);
            return apiDriverApp.put(`/api/hr/shifts/${match.id}/`, {
                name: match.name,
                hour_from: item.hour_from,
                hour_to: item.hour_to
            });
        } else {
            // console.log(`Creando nuevo shift: ${item.name}`);
            return apiDriverApp.post('/api/hr/shifts/', {
                name: item.name,
                hour_from: item.hour_from,
                hour_to: item.hour_to,
                color:'#35cc3a'
            });
        }
    });

    await Promise.all(requests);
};


export const processDrivers = async (dbMap, uploadedData) => {
    const requests = uploadedData.map(async (item) => {
        const match = dbMap.get(String(item.Employee));

        try {
            if (match) {
                // console.log(`Actualizando empleado con id ${match.user.username}`);
                const body = {};
                    body.username = item.Employee;
                    if (item.pin_code !== '') body.pin_code = item.pin_code;
                    if (item.Email !== '') body.email = item.Email;
                    if (item.FirstName) body.first_name = item.FirstName;
                    if (item.LastName) body.last_name = item.LastName;
                    if (item.Job !== '') body.job = item.Job;
                    if (item.MobilePhone !== '') body.mobile_phone = item.MobilePhone;
                    if (item.device !== '') body.device_type = item.device;
                        // Editar
                    console.log(body)  
                        await apiDriverApp.post('api/hr/employees/update_or_create/', body)
                // await apiDriverApp.patch(`api/hr/employees/${match.id}/`, body);
            } else {
                // console.log(`Creando nuevo empleado: ${item.Employee}`);
                const body = {};
                    body.username = item.Employee;
                    if (item.pin_code !== '') body.pin_code = item.pin_code;
                    if (item.Email !== '') body.email = item.Email;
                    if (item.FirstName) body.first_name = item.FirstName;
                    if (item.LastName) body.last_name = item.LastName;
                    if (item.Job !== '') body.job = item.Job;
                    if (item.MobilePhone !== '') body.mobile_phone = item.MobilePhone;
                    if (item.device !== '') body.device_type = item.device;
                        // create            
                    await apiDriverApp.post('api/hr/employees/update_or_create/', body)
                }
        } catch (error) {
            console.error(`Error processing employee ${item.Employee}:`, error.response.data);
            throw new Error(`Error processing employee ${item.Employee}: ${error.response.data.non_field_errors[0]}`);
        }
    });

    try {
        await Promise.all(requests);
    } catch (error) {
        console.error("Error processing employees:", error);
        throw error;
    }

    const { data: updatedDrivers } = await apiDriverApp.get('/api/hr/employees/');
    const matchedArray = updatedDrivers.filter(dbObj =>
        uploadedData.some(excelObj => excelObj.Employee == dbObj.user.username)
    ).map(dbObj => {
        const excelObj = uploadedData.find(excelObj => excelObj.Employee == dbObj.user.username);
        return {
            id: dbObj.id,
            username: dbObj.user.username,
            resourceId: dbObj.resource.id,
            autoScheduleTitle: excelObj.AutoScheduleTitle,
        };
    });
    return matchedArray;
};


export const processSchedules = async (newSchedules) => {

    try {
        const { data } = await apiDriverApp.post('/api/hr/calendar/import_calendars/', newSchedules);
        const uploadedSchedules = data.calendars.map(schedule => (
            {
                id: schedule.id,
                name: schedule.name
            }
        ))
        return uploadedSchedules
    } catch (error) {
        console.log(error.response)
    }
}

export const updateResourcesForSchedules = async (pilots, schedules) => {
    try {
        for (const schedule of schedules) {
            const matchedPilots = pilots.filter(pilot => pilot.autoScheduleTitle === schedule.name);
            const resourceIds = matchedPilots.map(pilot => pilot.resourceId);

            if (resourceIds.length > 0) {
                const response = await apiDriverApp.post(
                    `api/hr/schedule/${schedule.id}/manage_resources/`,
                    { resource_ids: resourceIds }
                );
            } else {
                console.log(`No drivers assigned to ${schedule.name}`);
            }
        }
    } catch (error) {
        console.error('Error updating resources', error.response.data || error.message);
    }
};

export const handleDownload = async () => {
    try {
      const response = await apiDriverApp.get('/api/hr/calendar/export/', {
        responseType: 'blob', // Especifica que el archivo se recibirá como un blob
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
  
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'AutoScheduleExportFile.xlsx'; // Nombre predeterminado
      if (contentDisposition) {
        const match = contentDisposition.match(/filename\*?="?([^"]+)"?/);
        if (match && match[1]) {
          fileName = match[1];
        }
      }
      link.setAttribute('download', fileName);
  
      document.body.appendChild(link);
      link.click();
  
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download error:', error);
    }
  };
  
import React from 'react'
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cellContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%", 
      width: "100%",
      padding: 2, 
      border: "0.2px solid #f5f0f0",
      borderBottom:'none'
    },
    daytime: {
      backgroundColor: "white",
      color: "white",
      fontSize:12,
      padding:5,
      margin:0,
      width: "100%",
      textAlign: "center"
    },
  }));


export const EmptyShift = ({id}) => {
    const classes = useStyles();
    return (
        <Grid item xs key={id} style={{ flexGrow: 1 }}>
            <div className={classes.cellContainer}>
                {/* <Paper className={`${classes.daytime}`}></Paper> */}
            </div>
        </Grid>
    )
}

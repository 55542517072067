import React from 'react'

export const RequestSummary = ({ data }) => {

    return (
        <div style={{
            marginTop: "30px",
            width: "100%",
        }}>
            <h3 className='text-left font-bold'>Employee Time Off Summary</h3>
            <table style={{
                width: "100%",
                marginTop: "15px",
                display: 'table',
                borderCollapse: "collapse"
            }}>
                <thead>
                    <tr style={{
                        backgroundColor: "#f8f9fa",
                        borderBottom: "2px solid #dee2e6",
                    }}>
                        <th style={{ width: '20%', textAlign: 'center', padding: '5px' }}>Employee</th>
                        <th style={{ width: '20%', textAlign: 'center', padding: '5px' }}>Total Days</th>
                        <th style={{ width: '20%', textAlign: 'center', padding: '5px' }}>Personal</th>
                        <th style={{ width: '20%', textAlign: 'center', padding: '5px' }}>Vacation</th>
                        <th style={{ width: '20%', textAlign: 'center', padding: '5px' }}>Sick</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data.length > 0 ? data.map(item => (
                            <tr style={{
                                borderBottom: "1px solid #dee2e6",
                            }} key={item.user}>
                                <td style={{ width: '20%', padding: '5px' }}>{item.fullName}</td>
                                <td style={{ width: '20%', textAlign: 'center', padding: '5px' }}>{item.totalDays}</td>
                                <td style={{ width: '20%', textAlign: 'center', padding: '5px' }}>{item.personal}</td>
                                <td style={{ width: '20%', textAlign: 'center', padding: '5px' }}>{item.vacation}</td>
                                <td style={{ width: '20%', textAlign: 'center', padding: '5px' }}>{item.sick}</td>
                            </tr>
                        )) : (
                            <tr>
                                <td>No Data</td>
                            </tr>
                        )
                    }
                </tbody>
                <tfoot>
                    <tr style={{
                        backgroundColor: "#f8f9fa",
                        fontWeight: "bold",
                    }}>
                        <td style={{ width: '20%', padding: '5px' }}>Total</td>
                        <td style={{ width: '20%', textAlign: 'center', padding: '5px' }}>{data.reduce((sum, user) => sum + user.totalDays, 0)}</td>
                        <td style={{ width: '20%', textAlign: 'center', padding: '5px' }}>{data.reduce((sum, user) => sum + user.personal, 0)}</td>
                        <td style={{ width: '20%', textAlign: 'center', padding: '5px' }}>{data.reduce((sum, user) => sum + user.vacation, 0)}</td>
                        <td style={{ width: '20%', textAlign: 'center', padding: '5px' }}>{data.reduce((sum, user) => sum + user.sick, 0)}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/apply';
import { getPathWithParams, hrApplicationsId } from '../../../../routesConfiguration/paths';
import { Link } from 'react-router-dom';
import IconAngleLeft from '../../../icons/angleLeftIcon';
import IconWatch from '../../../icons/watch';
import { IconTrashAlt } from '../../../icons/trash';
import LoadMask from '../../Utils/LoadMask';
import Pagination from '../../Utils/pagination';
import NumberFormat from 'react-number-format';
import ApplyForm from '../Details/form';
import Grid from '../../Utils/Grid/materialTable';

const ApplyGrid = (props) => {

    const {
        find,
        page,
        data,
        setSearch,
        search,
        history,
        filters,
        setFilters,
        create,
        destroy,
        downloadData,
        loadingGrid,
        loadingForm,
        formValues
    } = props;

    const tableRef = React.useRef();

    React.useEffect(()=>{
        find();
        if(tableRef && tableRef.current)
            tableRef.current.dataManager.changePageSize(10)
    }, []);

    const [newApply, setNewApply] = useState(false)

    const setFilterChange = (field, value) => {
        setFilters({[field]: value});
    }

    const sortedData = [...data.data].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

    const customFilterComponent = ({ field }) => {
        return (
            <div className="MuiFormControl-root MuiTextField-root" style={{ float: "left" }} >
                <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                    <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="Filter" data-testid="filter_list">
                            <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
                        </svg>
                    </div>
                    <input
                        defaultValue={filters[field] || ""}
                        aria-invalid="false" placeholder="" type="text" aria-label="filter data"
                        className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputTypeSearch MuiInput-inputTypeSearch"
                        onKeyUp={(event) => {
                            if (event.key === "Enter") {
                                setFilterChange(field, event.target.value);
                            }
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        newApply ? (
            <div className="d-flex flex-wrap justify-content-center px-5">
                <div className="col-12 mb-5 px-5">
                    <div className="px-5"> 
                        <ApplyForm
                        onSubmit={create}
                        onCancel={() => {
                            setNewApply(false);
                        }}
                        loading={loadingForm}
                        formValues={formValues}
                        initialValues={{
                            employment_history: [{}],
                            references: [{}],
                            dot_license: [{}],
                            dot_addresses: [{}],
                            vehicle_type: {
                                sedans: false,
                                suv: false,
                                vans: false,
                                bus: false,
                                coach: false,
                            },
                            background_check: '1',
                            work_before: {value:"NO", label:"NO"},
                            accidents: {
                                yes: "0",
                                reason: ""
                            },
                            traffic_violations: {
                                yes: "0",
                                reason: ""
                            },
                            denied_license: {
                                yes: "0",
                                reason: ""
                            },
                            suspended_license: {
                                yes: "0",
                                reason: ""
                            },
                            convicted: {
                                yes: '0',
                                reason: ''
                            },
                            physical_limitations: {
                                yes: '0',
                                reason: ''
                            },
                            status: {value: "application_submitted",
                                     label: "Application Submitted"
                            }, 
                        }}
                    />
                    </div>
                </div>
            </div>
        ) : (
            <div className="d-flex flex-wrap justify-content-center px-4">
                <div className="col-12 d-flex justify-content-start">
                    <div className="d-flex text-primary d-flex align-items-center clickable" onClick={() => history.goBack()}>
                        <IconAngleLeft className="smallIcon" />
                        <span> Back </span>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <div className="w-100">
                        <Grid
                            tableRef={tableRef}
                            title="Applications"
                            columns={[
                                {
                                    title: 'Full Name',
                                    field: 'first_name',
                                    width: '15%',
                                    sorting: false,
                                    render: (row) => `${row.first_name} ${row.middle_name} ${row.last_name}`,
                                    filterComponent: ({ columnDef }) =>
                                        customFilterComponent({
                                            field: columnDef.field,
                                        })
                                    },
                                {
                                    title: 'Phone',
                                    field: 'phone',
                                    width: '15%',
                                    sorting: false,
                                    render: (row) => <NumberFormat
                                        format="(###)-###-####"
                                        value={row.phone}
                                        displayType="text"
                                    />,
                                    filterComponent: ({ columnDef }) =>
                                        customFilterComponent({
                                            field: columnDef.field,
                                        })
                                },
                                {
                                    title: 'Email Address',
                                    field: 'email',
                                    width: '15%',
                                    sorting: false,
                                    render: (row) => `${row.email}`,
                                    filterComponent: ({ columnDef }) =>
                                        customFilterComponent({
                                            field: columnDef.field,
                                        })
                                },
                                {
                                    title: 'Experience',
                                    field: 'experience',
                                    width: '10%',
                                    sorting: false,
                                    render: (row) => `${row.experience && row.experience.label ? row.experience.label : row.experience}`,
                                    filterComponent: ({ columnDef }) => (
                                        <select className="MuiInputBase-input select-border-bottom"
                                            onChange={({ target: { value }}) => setFilterChange(columnDef.field, value)}
                                        >
                                            <option selected={!filters[columnDef.field] || filters[columnDef.field]==""} value="">ALL</option>
                                            <option selected={filters[columnDef.field]=="0-1_years"} value="0-1_years">0-1 Years</option>
                                            <option selected={filters[columnDef.field]=="1-3_years"} value="1-3_years">1-3 Years</option>
                                            <option selected={filters[columnDef.field]=="4-7_years"} value="4-7_years">4-7 Years</option>
                                            <option selected={filters[columnDef.field]=="8-10_years"} value="8-10_years">8-10 Years</option>
                                            <option selected={filters[columnDef.field]=="10-more_years"} value="10-more_years">10+ Years</option>
                                        </select>
                                    ),
                                },
                                {
                                    title: 'City',
                                    field: 'city',
                                    width: '10%',
                                    sorting: false,
                                    filterComponent: ({ columnDef }) =>
                                        customFilterComponent({
                                            field: columnDef.field,
                                        })
                                },
                                {
                                    title: 'State',
                                    field: 'state',
                                    width: '10%',
                                    sorting: false,
                                    filterComponent: ({ columnDef }) =>
                                        customFilterComponent({
                                            field: columnDef.field,
                                        })
                                },
                                {
                                    title: 'Age',
                                    field: 'age',
                                    width: '10%',
                                    sorting: false,
                                    render: (row) => `${row.age ? `${row.age} Year${row.age !== 1 ? 's' : ''}` : ''}`,
                                    filtering: false,
                                },
                                {
                                    title: 'Submission Date',
                                    field: 'submission_date',
                                    width: '15%',
                                    sorting: false,
                                    render: (row) => `${row.createdAt ? `${row.createdAt.split('T')[0]}` : ''}`,
                                    filtering: false,
                                },
                                {
                                    title: 'Status',
                                    field: 'status',
                                    width: '15%',
                                    sorting: false,
                                    render: (row) => `${row.status ? `${row.status.label}` : 'Application Submitted'}`,
                                    filtering: false,
                                },
                                {
                                    title: "Actions",
                                    field: "_id",
                                    width: "10%",
                                    align: "center",
                                    filtering: false,
                                    render: (row) => (
                                        <span>
                                            <Link
                                                to={getPathWithParams(
                                                    hrApplicationsId,
                                                    "id",
                                                    row._id
                                                )}
                                                className="text-dark text-decoration-none px-1"
                                            >
                                                <IconWatch className="smallIcon clickable" />
                                            </Link>
                                            <span onClick={() => destroy(row._id)}>
                                                <IconTrashAlt className="smallIcon px-1 clickable" />
                                            </span>
                                        </span>
                                    )
                                },
                            ]}
                            onRowClick={null}
                            actions={[{
                                icon: () =>
                                    <div className="btn btn-primary px-2 py-0 align-self-end rounded-circle">
                                        <h3>+</h3>
                                    </div>,
                                isFreeAction: true,
                                onClick: ()=>setNewApply(!newApply)
                            }]}
                            data={sortedData}
                            onSearchChange={(search) => {
                                setSearch(search);
                            }}
                            onPageChange={(p) => find(p + 1)}
                            isLoading={loadingGrid}
                            page={page - 1}
                            totalCount={data.total}
                            options={{
                                idSynonym: "_id",
                                filtering: true,
                                searchText: "Search by name or email",
                                actionsColumnIndex: -1,
                                pageSize: 10,
                                pageSizeOptions: [],
                                headerStyle: {
                                    backgroundColor: '#D50032',
                                    color: '#FFF',
                                    fontSize: '1rem',
                                },
                                cellStyle: {
                                    fontSize: '1rem',
                                    // wordBreak: "break-word"
                                },
                                scrollX: true,
                                // maxBodyWidth: "1000px", 
                                // tableLayout: "fixed"
                                
                            }}
                        />

                        <div className="d-flex mt-3 mb-5">
                            <div className="d-flex flex-1">
                                <div className="d-flex clickable" onClick={downloadData}>
                                    <span style={{ width: "12px" }}>
                                        <svg
                                            xmlns="SVG namespace "
                                            viewBox="0 0 384 512"
                                        >
                                            <path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z" />
                                        </svg>
                                    </span>
                                    <span className="pl-2 font-bold" style={{ textDecoration: "underline" }}>
                                        Download Driver Data
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    )
}

const mstp = (state) => ({
    ...state.apply,
    formValues: state.form.applyForm && state.form.applyForm.values,
})

const mdtp = {
    ...actions
}

export default connect(mstp, mdtp)(ApplyGrid);

import React from 'react'
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { EmptyShift } from './EmptyShift';
import { NormalShift } from './NormalShift';
import { PartialEmptyLeft } from './PartialEmptyLeft';
import { PartialEmptyRight } from './PartialEmptyRight';
import { PartialRight } from './PartialRight';
import { FinalCell } from './FinalCell';
import { HourlyShift } from './HourlyShift';
import { HourlyShiftEnd } from './HourlyShiftEnd';
import { FinalCellHourly } from './FinalCellHourly';
import { FirstlCell } from './FirstCell';
import {FirstCellHourly} from './FirstCellHourly'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    border: "0.2px solid #f5f0f0",
  },
  cell: {
    padding: theme.spacing(1),
    textAlign: "center",
    borderRadius: 0,
    border: "0.2px solid #f5f0f0",
    boxShadow: "none",
    borderBottom: 'none'
  },
  driverCell: {
    padding: theme.spacing(1),
    textAlign: "left",
    borderRadius: 0,
    border: "0.2px solid #f5f0f0",
    boxShadow: "none",
    borderBottom: 'none'
  },
  header: {
    fontWeight: "bold",
    backgroundColor: "#707070",
    color: 'white'
  },
  cellContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%", // Asegura que ocupe toda la celda
    width: "100%",
    padding: 2, // Espaciado dentro de la celda
    border: "0.2px solid #f5f0f0",
    borderBottom: 'none'
  },
  cellOverlapContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%", // Asegura que ocupe toda la celda
    width: "100%",
    padding: 2, // Espaciado dentro de la celda
    borderTop: "0.2px solid #f5f0f0",
    borderBottom: 'none'
  },
  cellOverlapShiftContainer: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    height: "100%", // Asegura que ocupe toda la celda
    width: "100%",
    padding: 0,
    borderTop: "0.2px solid #f5f0f0",
    borderBottom: 'none'
  },
  cellOverlapShiftContainerEnd: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    height: "100%", // Asegura que ocupe toda la celda
    width: "100%",
    padding: 0,
    borderTop: "0.2px solid #f5f0f0",
    borderBottom: 'none'
  },
  daytime: {
    backgroundColor: "#2E7D32",
    color: "white",
    fontSize: 12,
    padding: 5,
    margin: 0,
    width: "100%",
    textAlign: "center"
  },
  daytimeOverlap: {
    backgroundColor: "#2E7D32",
    color: "white",
    fontSize: 12,
    padding: 5,
    margin: 0,
    width: "100%",
    textAlign: "center",
    display: "flex", // Habilita flexbox
    alignItems: "center", // Centra verticalmente
    justifyContent: "center", // Centra horizontalmente
    gap: "4px"
  },
  shiftStart: {
    backgroundColor: "#D50032",
    color: "white",
    fontSize: 12,
    padding: 5,
    paddingLeft: 0,
    margin: 0,
    width: "100%",
    textAlign: "left",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    display: "flex", // Habilita flexbox
    alignItems: "center", // Centra verticalmente
    justifyContent: "center", // Centra horizontalmente
    gap: "4px"
  },
  shiftEnd: {
    backgroundColor: "#D50032",
    color: "white",
    fontSize: 12,
    padding: 5,
    paddingRight: 0,
    margin: 0,
    width: "100%",
    textAlign: "right",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    display: "flex", // Habilita flexbox
    alignItems: "center", // Centra verticalmente
    justifyContent: "center", // Centra horizontalmente
    gap: "4px"
  },
}));

export const WeeklySchedule = ({ drivers }) => {

  const classes = useStyles();

  const days = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri",];

  const getWeekDates = (driver) => {
    if (!driver || !driver.schedule || driver.schedule.length === 0) return [];
    const [year, month, day] = driver.schedule[1].date.split("-").map(Number);
    const firstDate = new Date(year, month - 1, day);

    return days.map((_, index) => {
      const newDate = new Date(firstDate);
      newDate.setDate(firstDate.getDate() + index);
      return newDate.getDate();
    });
  };

  const getMonth = (driver) => {
    if (!driver || !driver.schedule || driver.schedule.length === 0) return null;
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const [, month] = driver.schedule[0].date.split("-");
    return monthNames[parseInt(month, 10) - 1];
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>{getMonth(drivers[0])}</h4>
      </div>
      <Grid container spacing={0} xs={12} className={classes.container}>
        {/* Header */}
        <Grid item xs={3}>
          <Paper className={`${classes.cell} ${classes.header}`}>Driver</Paper>
        </Grid>
        {days.map((day, index) => {
          const dayNumber = getWeekDates(drivers[0])[index] || "";
          return (
            <Grid item xs key={index} style={{ flexGrow: 1 }}>
              <Paper className={`${classes.cell} ${classes.header}`}>
                {`${day} ${dayNumber}`}
              </Paper>
            </Grid>
          );
        })}

        {/* Rows */}
        {drivers.map((driver, rowIndex) => (
          <Grid container item xs={12} key={rowIndex + "z"} spacing={0}>
            <Grid item xs={3} key={driver.name}>
              <Paper className={classes.driverCell}>{driver.name}</Paper>
            </Grid>
            {driver.schedule.map((day, colIndex) => (
              (day.isLastWeek && day.hasOverlap && !day.isNumeric) && day.leaves.length <= 0 ? (
                <FirstlCell id={colIndex} color={day.shifts[0].color} name={day.shifts[0].name} />
              ) : (day.isLastWeek && day.hasOverlap && day.isNumeric) && day.leaves.length <= 0 ? (
                <FirstCellHourly id={colIndex} color={day.shifts[0].color} from={day.shifts[0].hour_from} />)
            : (day.isEmptyDay !== true && day.hasOverlap !== true && !day.isLastWeek) && day.leaves.length <= 0 ? (
                  <NormalShift id={colIndex} color={day.shifts[0].color} name={day.shifts[0].name} />
                ) : day.leaves.length > 0 && !day.isLastWeek ? (
                  <NormalShift id={colIndex} color={day.leaves[0].leave_type.color} name={day.leaves[0].leave_type.name} />
                ) : day.hasOverlap && day.pastDayOverlap == false && day.day_name !== "Friday" && day.isNumeric == false && !day.isLastWeek ? (
                  <React.Fragment>
                    <PartialEmptyLeft id={colIndex + 'A'} />
                    <NormalShift id={colIndex} color={day.shifts[0].color} name={day.shifts[0].name} overlap={true} />
                  </React.Fragment>
                ) : day.hasOverlap && day.pastDayOverlap == false && day.day_name !== "Friday" && day.isNumeric && !day.isLastWeek ? (
                  <React.Fragment>
                    <PartialEmptyLeft id={colIndex + 'A'} />
                    <HourlyShift id={colIndex} color={day.shifts[0].color} from={day.shifts[0].hour_from} to={day.shifts[0].hour_to} />
                  </React.Fragment>
                ) : day.pastDayOverlap == true && day.hasOverlap == false && day.isEmptyDay == true && !day.isLastWeek ? (
                  <React.Fragment>
                    <PartialEmptyRight id={colIndex} />
                  </React.Fragment>
                ) : day.hasOverlap == true && day.pastDayOverlap == true && day.isEmptyDay == false && day.day_name !== "Friday" && day.isNumeric == false && !day.isLastWeek ? (
                  <React.Fragment>
                    <NormalShift id={colIndex} color={day.shifts[0].color} name={day.shifts[0].name} overlap={true} />
                  </React.Fragment>
                ) : day.hasOverlap == true && day.pastDayOverlap == true && day.isEmptyDay == false && day.day_name !== "Friday" && day.isNumeric && !day.isLastWeek ? (
                  <React.Fragment>
                    <HourlyShift id={colIndex} color={day.shifts[0].color} from={day.shifts[0].hour_from} to={day.shifts[0].hour_to} />
                  </React.Fragment>
                ) : day.day_name === "Friday" && day.hasOverlap == true && day.isEmptyDay == false && day.pastDayOverlap == false && day.isNumeric == false && !day.isLastWeek ? (
                  <React.Fragment>
                    <FinalCell id={colIndex} color={day.shifts[0].color} name={day.shifts[0].name} />
                  </React.Fragment>
                ) : day.day_name === "Friday" && day.hasOverlap == true && day.isEmptyDay == false && day.pastDayOverlap == false && day.isNumeric && !day.isLastWeek ? (
                  <React.Fragment>
                    <FinalCellHourly id={colIndex} color={day.shifts[0].color} from={day.shifts[0].hour_from} />
                  </React.Fragment>
                ) : day.day_name === "Friday" && day.hasOverlap == true && day.isEmptyDay == false && day.pastDayOverlap == true && day.isNumeric == false && !day.isLastWeek ? (
                  <React.Fragment>
                    <PartialRight id={colIndex} color={day.shifts[0].color} name={day.shifts[0].name} />
                  </React.Fragment>
                ) : day.day_name === "Friday" && day.hasOverlap == true && day.isEmptyDay == false && day.pastDayOverlap == true && day.isNumeric && !day.isLastWeek ? (
                  <React.Fragment>
                    <HourlyShiftEnd id={colIndex} color={day.shifts[0].color} from={day.shifts[0].hour_from} />
                  </React.Fragment>
                )
                  : !day.isLastWeek ? <EmptyShift id={colIndex} /> : ''
            ))}

          </Grid>
        ))}
      </Grid>
    </React.Fragment>

  );
};
import React from 'react'
import { Grid, Paper, makeStyles } from "@material-ui/core";
import ArrowLeftIcon from '../../../../icons/arrow-left';

const useStyles = makeStyles((theme) => ({
    cellOverlapShiftContainer: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        height: "100%", 
        width: "100%",
        padding: 0,
        borderTop: "0.2px solid #f5f0f0",
        borderBottom: 'none'
      },
    shiftStart: {
        backgroundColor: "#D50032",
        color: "white",
        fontSize: 12,
        padding: 5,
        paddingLeft: 0,
        margin: 0,
        width: "100%",
        textAlign: "left",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        display: "flex", 
        alignItems: "center",
        justifyContent: "center", 
        gap: "4px"
      },
}));

export const FirstCellHourly = ({ id, color, from }) => {
    const classes = useStyles();
    const formatHour = (hour) => String(hour).padStart(2, '0') + ":00";
    return (
        <Grid item xs key={id} style={{ flexGrow: 0.5, display:'flex'}}>
            <div className={classes.cellOverlapShiftContainer}
            >
                <Paper
                    className={classes.shiftStart}
                    style={{borderLeft:"1px solid #f5f0f0", borderTop:"1px solid #f5f0f0", borderBottom:"1px solid #f5f0f0", borderRight:"none", color:'white', backgroundColor:'#FF9AB2'}}
                >
                   <ArrowLeftIcon size={12} /> {formatHour(from)} 
                </Paper>
            </div>
        </Grid>
    )
}
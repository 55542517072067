import React from "react";
import { Chart } from "react-google-charts";


export default function ColumnChart({data}) {
  const formattedData = data.length > 0 
  ? [["Month", "Personal", "Vacation", "Sick"], ...data] 
  : [["Month", "Personal", "Vacation", "Sick"], ["No Data", 0, 0, 0]];
  
  const options = {
    chartArea: { width: "90%", height: "80%", top: 10 }, 
    isStacked: true,
    colors: ["#1976D2", "#2E7D32", "#6c757d"],
    hAxis: { title: "", textStyle: { fontSize: 12 } },
    vAxis: { textPosition: "none" }, 
    legend: { 
      position: "bottom", 
      textStyle: { fontSize: 16 },
    },
  };
return (
  <div style={{ width: "100%", minWidth: 0 }}>
      <h3 className="text-center mb-0">Monthly Distribution</h3>
      <Chart chartType="ColumnChart" width="100%" height="350px" data={formattedData} options={options} />
  </div>
);
}
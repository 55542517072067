import React from 'react'
import { Chart } from "react-google-charts"

export default function DonutChart({ data = [], averageDays = 0 }) {

    const formattedData = [
        ["Type", "Percentage"],
        ...((data.length > 0) ? data.map(({ type, percentage }) => [type, percentage]) : [["No Data", 100]])
    ];
    
    const options = {
        pieHole: 0.5,
        pieSliceText: "percentage",
        colors: ["#1976D2", "#2E7D32", "#757575"],
        legend: { 
            position: "bottom", 
            textStyle: { fontSize: 16 },
        },
        chartArea: { top: 20, bottom: 40, width: "90%", height: "80%" },
    };

    return (
        <div style={{ width: "100%", minWidth: 0 }}>
            <h3 className="text-center mb-0">Average Request Length</h3>
            <div style={{ position: "relative", width: "100%",display: "flex", justifyContent: "center" }}>
                <Chart chartType="PieChart" width="100%" height="350px" data={formattedData} options={options} />
                <div
                    style={{
                        position: "absolute",
                        top: "47%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <div className='text-center font-bold' style={{ fontSize: "1.5rem" }}>{averageDays}</div>
                    <div style={{ fontSize: "1rem", color: '#6c757d' }}>days avg</div>
                </div>
            </div>
        </div>
    );
}
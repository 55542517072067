import React, { useEffect, useState, useRef } from 'react'
import { WeeklySchedule } from './WeeklySchedule'
import IconFilterAlt from '../../../../icons/filterIconAlt';
import { Dropdown, Checkbox } from 'semantic-ui-react';
import ChevronRightIcon from '../../../../icons/chevron-right';
import ChevronLeftIcon from '../../../../icons/chevron-left';
import { apiDriverApp } from '../../../../../libs/apiDriverApp';
import LoadMask from '../../../Utils/LoadMask';

export const WeeklyView = ({ shifts, stations }) => {
  const [search, setSearch] = useState("");
  const [activeSchedules, setActiveSchedules] = useState([])
  const [filteredSchedules, setFilteredSchedules] = useState([])
  const [mainOpen, setMainOpen] = useState(false);
  const [nestedOpen, setNestedOpen] = useState(false);
  const [nestedOpen2, setNestedOpen2] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const dropdownRef = useRef(null);

  const toggleLocation = (value) => {
    setSelectedLocations((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
    );
  };

  const toggleShift = (value) => {
    setSelectedShifts((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
    );
  };

  const checkSchedule = (schedule, pastWeek) => {
    const lastDay = pastWeek.days.find(day => day.day_name === "Friday") || null;
    const daysToProcess = lastDay ? [{ ...lastDay, isLastWeek: true }, ...schedule.days] : [...schedule.days];
  
    return daysToProcess.map((day, index, daysArray) => {
      const hasOverlap = day.shifts.some(shift => shift.hour_from > shift.hour_to);
      const isEmptyDay = day.shifts.length === 0 && day.leaves.length === 0;
  
      const isNumeric = day.shifts.length > 0 && day.shifts.every(shift =>
        shift.color === "#ffffff" && shift.name.includes("-")
      );
  
      const previousDay = daysArray[index - 1] || null;
      const pastDayEmpty = previousDay ? (previousDay.shifts.length === 0 && previousDay.leaves.length === 0) : false;
      const pastDayOverlap = previousDay ? previousDay.shifts.some(shift => shift.hour_from > shift.hour_to) : false;
  
      return {
        ...day,
        hasOverlap,
        isEmptyDay,
        pastDayEmpty,
        pastDayOverlap,
        isNumeric
      };
    });
  };

  const getNextSaturday = (date) => {
    const currentDay = date.getDay(); 
    const daysUntilSaturday = (6 - currentDay) || 7;
    const nextSaturday = new Date(date); 
    nextSaturday.setDate(date.getDate() + daysUntilSaturday); 
    return nextSaturday
  }

  const getPreviousFriday = (date) => {
    const currentDay = date.getDay(); 
    const daysSinceFriday = (currentDay - 5 + 7) % 7 || 7; 
    const previousFriday = new Date(date); 
    previousFriday.setDate(date.getDate() - daysSinceFriday); 
    return previousFriday;
  }

  const getWeeklySchedules = async () => {
    setLoading(true)
    const today = new Date();
    const pastFriday = getPreviousFriday(today);
    const end = today.toISOString().split("T")[0];
    const start = pastFriday.toISOString().split("T")[0];

    setCurrentDate(today)
    try {
      const { data } = await apiDriverApp.get(`api/hr/driver-schedule/all_driver_schedules/?start_date=${start}&end_date=${end}`);
      const weeklySchedules = data.schedules.map(item => ({
        name: `${item.driver.user.first_name} ${item.driver.user.last_name}`,
        station: item.driver.station ? item.driver.station.name : null,
        schedule: checkSchedule(item.schedule[1], item.schedule[0]),
      }))
      setActiveSchedules(weeklySchedules)
      // setFilteredSchedules(weeklySchedules)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const fetchNextWeekSchedules = async()=>{
    setLoading(true)
    const nextSaturday = getNextSaturday(currentDate);
    const formattedDate = nextSaturday.toISOString().split("T")[0];
    setCurrentDate(nextSaturday)
    const lastFriday = getPreviousFriday(nextSaturday)
    const start = lastFriday.toISOString().split("T")[0];
    try {
      const { data } = await apiDriverApp.get(`api/hr/driver-schedule/all_driver_schedules/?start_date=${start}&end_date=${formattedDate}`);
      const weeklySchedules = data.schedules.map(item => ({
        name: `${item.driver.user.first_name} ${item.driver.user.last_name}`,
        station: item.driver.station ? item.driver.station.name : null,
        schedule: checkSchedule(item.schedule[1], item.schedule[0])
      }))
      setActiveSchedules(weeklySchedules)
      // setFilteredSchedules(weeklySchedules)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const fetchPastWeekSchedules = async()=>{
    setLoading(true)
    const pastFriday = getPreviousFriday(currentDate);
    const formattedDate = pastFriday.toISOString().split("T")[0];
    setCurrentDate(pastFriday)
    const lastWeekFriday = getPreviousFriday(pastFriday)
    const start = lastWeekFriday.toISOString().split("T")[0];
    try {
      const { data } = await apiDriverApp.get(`api/hr/driver-schedule/all_driver_schedules/?start_date=${start}&end_date=${formattedDate}`);
      const weeklySchedules = data.schedules.map(item => ({
        name: `${item.driver.user.first_name} ${item.driver.user.last_name}`,
        station: item.driver.station ? item.driver.station.name : null,
        schedule: checkSchedule(item.schedule[1], item.schedule[0])
      }))
      setActiveSchedules(weeklySchedules)
      // setFilteredSchedules(weeklySchedules)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setMainOpen(false);
        setNestedOpen(false);
        setNestedOpen2(false);
      }
    }

    if (mainOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [mainOpen]);

  useEffect(() => {
    getWeeklySchedules()
  }, [])

  useEffect(() => {
    let filtered = [...activeSchedules];

    if (selectedLocations.length > 0) {
      filtered = filtered.filter((item) => selectedLocations.includes(item.station));
    }

    if (selectedShifts.length > 0) {
      filtered = filtered.filter((item) =>
        item.schedule.some(
          (sched) => sched.shifts.length > 0 && selectedShifts.includes(sched.shifts[0].name)
        )
      );
    }

    if (search.trim() !== "") {
      filtered = filtered.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFilteredSchedules(filtered);
  }, [selectedLocations, selectedShifts, search, activeSchedules]);

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className='d-flex align-items-center w-50'>
          <div className="w-75">
            <input
              placeholder="Search..."
              className="form-control"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className='px-0 justify-content-end align-items-center ml-2'>
            <div className='justify-content-end' ref={dropdownRef}>
              <Dropdown
                className="custom-dropdown"
                icon={null}
                open={mainOpen}
                onClick={() => setMainOpen(!mainOpen)}
                trigger={
                  <div style={{ display: 'flex', alignItems: 'center', height: '1.3rem' }}>
                    <IconFilterAlt className="h-100" />
                  </div>
                }
              >
                <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
                  <div className="p-2" style={{ display:'flex', alignItems:'center' }}>

                    <Dropdown
                      text="Location"
                      open={nestedOpen}
                      onClick={() => {setNestedOpen(!nestedOpen), setNestedOpen2(false)}}
                    >
                      <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: "10px", padding: "20px" }}>
                          {stations.map((option) => (
                            <Dropdown.Item key={option.id} onClick={(e) => e.stopPropagation()}>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox
                                  label={option.name}
                                  checked={selectedLocations.includes(option.name)}
                                  onChange={() => toggleLocation(option.name)}
                                />
                              </div>
                            </Dropdown.Item>
                          ))}
                        </div>

                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown
                      text="Shift"
                      style={{ marginLeft: 15 }}
                      open={nestedOpen2}
                      onClick={() => {setNestedOpen2(!nestedOpen2), setNestedOpen(false)}}
                    >
                      <Dropdown.Menu onClick={(e) => e.stopPropagation()} style={{ width: 250 }}>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px", padding: "20px" }}>
                          {shifts.map((option) => (
                            <div key={option.id} style={{ display: "flex", alignItems: "center" }}>
                              <Checkbox
                                label={option.name}
                                checked={selectedShifts.includes(option.name)}
                                onChange={() => toggleShift(option.name)}
                              />
                            </div>
                          ))}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>

                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center" style={{ gap: 15 }}>
          <button
            className="btn rounded shadow-sm d-flex flex-row align-items-center gap-2"
            style={{ backgroundColor: '#D50032' }}
            onClick={getWeeklySchedules}
          >
            <span style={{ margin: 0, cursor: 'pointer', marginLeft: 5, backgroundColor: '#D50032', color: 'white' }}>Current Week</span>
          </button>
          <button
            className="btn rounded shadow-sm d-flex flex-row align-items-center gap-2"
            style={{ backgroundColor: '#343A40' }}
            onClick={fetchPastWeekSchedules}
          >
            <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem', color: 'white' }}>
              <ChevronLeftIcon className="h-100" />
            </div>
            <span style={{ margin: 0, cursor: 'pointer', marginLeft: 5, backgroundColor: '#343A40', color: 'white' }}>Previous 7 Days</span>
          </button>
          <button
            className="btn rounded shadow-sm d-flex flex-row align-items-center gap-2"
            style={{ backgroundColor: '#343A40' }}
            onClick={fetchNextWeekSchedules}
          >
            <span style={{ margin: 0, cursor: 'pointer', marginLeft: 5, backgroundColor: '#343A40', color: 'white' }}>Next 7 Days</span>
            <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem', color: 'white' }}>
              <ChevronRightIcon className="h-100" />
            </div>
          </button>
        </div>

      </div>
      <LoadMask loading={loading} light>
         <div style={{
        height: "700px",
        overflowY: "auto",
        overflowX: "hidden",
      }}>
        <WeeklySchedule drivers={filteredSchedules}/>
      </div>
      </LoadMask>
     
    </React.Fragment>
  )
}

import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal';
import IconFloppyDisk from '../../../icons/floppy-disk';
import { connect } from 'react-redux';
import { apiDriverApp } from '../../../../libs/apiDriverApp';
import app from '../../../../libs/apiClient';
const emailService = app.service("/sendGrid");

const ModalTimeOffRequest = ({ showModal, handleCloseModal, request, title, message, getLeaves, getStatusStyle, user }) => {

  const [statusText, setStatusText] = useState('');
  const [filter1, setFilter1] = useState("");

  const statusOptions = [
    { label: 'Pending', value: 'sent' },
    { label: 'Approved', value: 'approved' },
    { label: 'Denied', value: 'denied' }
  ];

  const handleFilter1Change = (event) => {
    setFilter1(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const data = await apiDriverApp.post(`api/hr/leave/${request.id}/update_status/`, {

        status: filter1,
        modified_by: `${user.firstName.charAt(0).toUpperCase()}. ${user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)}`

      });
      if (data.status == 200) {
        emailService.create(
          {
            from: {
              email: "driverapp-timeoff@connectbbc.com",
            },
            personalizations: [{
              to: [{ email: 'drivers@connectbbc.com' }],
              dynamic_template_data: {
                subject: `Updated ${request.type} Time-Off Request`,
                title: `Update time-off request for ${request.name}`,
                message: `Time-off request for ${request.name} from ${request.startDate} through ${request.endDate} has been updated to ${data.data.new_status} by ${user.firstName} ${user.lastName}.`,
                email_link: 'https://portal.connectbbc.com/#/settings/driver-schedules/time-off',
                email_link_text:'Go to dashboard'
              }
            }],
            template_id: 'd-50889e22ce0041d8b5255f99a900d35f',
          }
        )
      }
      getLeaves()
      handleCloseModal()
    } catch (error) {
      console.log(error.response)
    }
  }

  useEffect(() => {
    if (request) {
      const { text } = getStatusStyle(request.status)
      setStatusText(text)
      setFilter1(request.status)
    }
  }, [request])

  return (
    <React.Fragment>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        showCloseIcon={false}
        styles={{ modal: { minWidth: '30%', borderRadius: '8px', marginTop: 50, padding: 0 } }}
      >
        <div className="d-flex row m-0 justify-content-between align-items-center px-3 pt-3">
          <div className='col-6 p-0'>
            <h4 style={{ fontWeight: 'bold' }}>Time Off Request</h4>
          </div>

          <div className="d-flex justify-content-between align-items-center">

            <button onClick={handleCloseModal} className="btn d-flex align-items-center rounded shadow-sm px-3 py-2 me-3" style={{ backgroundColor: '#D50032', color: 'white' }}>
              <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                X
              </div>
              <span className="ml-1">Close</span>
            </button>

            <button className="btn border border-dark rounded shadow-sm px-3 py-2 d-flex align-items-center ml-3" onClick={handleSubmit}>
              <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                <IconFloppyDisk className='h-100' />
              </div>
              <span className="ml-1" style={{ fontWeight: 'bold' }}>Save</span>
            </button>
          </div>


        </div>

        <hr />

        <div className="container px-4">
          <div style={{ display: 'flex', alignItems: 'center', }}>
            <div className='w-50 justify-content-center align-items-center'>
              <div className='mb-3'>
                <span>
                  <strong>
                    {request && `${request.name}`}
                  </strong>
                </span>
              </div>

              <select
                id="select1"
                className="form-control mb-2"
                value={filter1}
                onChange={handleFilter1Change}
              >
                {
                  statusOptions.map((status, index) => (
                    <option key={index} value={status.value}>{status.label}</option>
                  ))
                }
              </select>
            </div>
            <div className='w-50 d-flex flex-column align-items-end'>
              <div className='mb-4'>
                <label><strong>Start Date: </strong>{request && request.startDate}</label>
              </div>
              <div>
                <label><strong>End Date: </strong>{request && request.endDate}</label>
              </div>
            </div>
          </div>
          <div className='mt-4 text-right'>
            {request && `${request.dateRequested} - ${statusText} - ${request.latestUser}`}
          </div>
        </div>

      </Modal>
    </React.Fragment>
  )
};

const mapStateToProps = (state) => ({
  user: state.login.userInfo,
});

export default connect(mapStateToProps)(ModalTimeOffRequest);


import React, { useEffect, useState } from "react";
import "./Calendar.css";
import { apiDriverApp } from "../../../../../libs/apiDriverApp";
import LoadMask from "../../../Utils/LoadMask";

const Calendar = () => {
    const [date, setDate] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
    });
    const [currentCalendar, setCurrentCalendar] = useState([])
    const [eventsByDay, setEventsByDay] = useState([])
    const [eventList, setEventList] = useState(null)
    const [loading, setLoading] = useState(false)
    const today = new Date();
    const todayDay = today.getDate();
    const isCurrentMonth = today.getFullYear() === date.year && today.getMonth() === date.month;

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    useEffect(() => {
        const firstDay = new Date(date.year, date.month, 1).getDay();
        const daysInMonth = new Date(date.year, date.month + 1, 0).getDate();
        const daysArray = Array.from({ length: firstDay }, () => null).concat(
            Array.from({ length: daysInMonth }, (_, i) => i + 1)
        );
        const totalCells = Math.ceil(daysArray.length / 7) * 7;
        while (daysArray.length < totalCells) {
            daysArray.push(null);
        }
        setCurrentCalendar(daysArray);
    }, [date.month])

    const mapEventsToCalendar = (events) => {
        const daysInMonth = new Date(date.year, date.month + 1, 0).getDate();
        const eventsByDay = {};

        events.forEach((event) => {
            const fromDate = new Date(event.from_date + "T00:00:00Z");
            const toDate = new Date(event.to_date + "T00:00:00Z");

            if (
                (fromDate.getUTCFullYear() === date.year && fromDate.getUTCMonth() === date.month) ||
                (toDate.getUTCFullYear() === date.year && toDate.getUTCMonth() === date.month)
            ) {
                let startDay = fromDate.getUTCMonth() === date.month ? fromDate.getUTCDate() : 1;
                let endDay = toDate.getUTCMonth() === date.month ? toDate.getUTCDate() : daysInMonth;

                for (let day = startDay; day <= endDay; day++) {
                    if (!eventsByDay[day]) eventsByDay[day] = [];
                    eventsByDay[day].push(event);
                }
            }
        });

        return eventsByDay;
    };

    const getMonthRange = (year, month) => {
        const firstDay = new Date(year, month, 1).toISOString().split("T")[0];
        const lastDay = new Date(year, month + 1, 0).toISOString().split("T")[0];

        return { firstDay, lastDay };
    };

    useEffect(() => {
        const fetchNextMonthLeaves = async () => {
            setLoading(true)
            const { firstDay, lastDay } = getMonthRange(date.year, date.month);
            console.log("Fetching leaves for:", firstDay, lastDay);
            try {
                const { data } = await apiDriverApp.get(`api/hr/leave/?from_date=${firstDay}&to_date=${lastDay}`);
                console.log('datis', data.sort((a, b) => new Date(a.from_date) - new Date(b.from_date)))
                setEventList(data.sort((a, b) => new Date(a.from_date) - new Date(b.from_date)))
                setEventsByDay(mapEventsToCalendar(data))
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        };

        fetchNextMonthLeaves();
    }, [date.month, date.year]);

    const fetchPastMonth = () => {
        setDate(prevDate => {
            const newMonth = prevDate.month === 0 ? 11 : prevDate.month - 1;
            const newYear = prevDate.month === 0 ? prevDate.year - 1 : prevDate.year;
            return { year: newYear, month: newMonth };
        });
    };

    const fetchNextMonth = () => {
        setDate(prevDate => {
            const newMonth = prevDate.month === 11 ? 0 : prevDate.month + 1;
            const newYear = prevDate.month === 11 ? prevDate.year + 1 : prevDate.year;
            return { year: newYear, month: newMonth };
        });
    };

    const formatDate = (dateStr) => {
        const date = new Date(`${dateStr}T00:00:00Z`);
        return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', timeZone: 'UTC' });
    };

    return (
        <div className="calendar-content">
            <div className="calendar-header">
                <button onClick={fetchPastMonth}>&lt;</button>
                <h2>{monthNames[date.month]} {date.year}</h2>
                <button onClick={fetchNextMonth}>&gt;</button>
            </div>
            <LoadMask loading={loading} light>
                <div className="calendar-grid">
                    <div className="day-header">Sun</div>
                    <div className="day-header">Mon</div>
                    <div className="day-header">Tue</div>
                    <div className="day-header">Wed</div>
                    <div className="day-header">Thu</div>
                    <div className="day-header">Fri</div>
                    <div className="day-header">Sat</div>

                    {currentCalendar.map((day, index) => (
                        <div
                            key={index}
                            className={`day-cell ${day ? "" : "other-month"} ${isCurrentMonth && day === todayDay ? "today" : ""}`}
                        >
                            <div className="day-number">{day || ""}</div>
                            {day && eventsByDay[day] && (
                                <React.Fragment>
                                    {!loading && eventsByDay[day].map((event, i) => (
                                        <div key={i} className='event' style={{
                                            backgroundColor: event.leave_type.name === 'Personal' ? '#0d6efd'
                                                : event.leave_type.name === 'Vacation' ? '#198754'
                                                    : event.leave_type.name === 'Sick' ? '#6c757d'
                                                        : ''
                                        }}>
                                            {event.employee.user.first_name} {event.employee.user.last_name} - {event.leave_type.name || "Unknown event"}
                                        </div>
                                    ))}
                                </React.Fragment>
                            )}
                        </div>

                    ))}

                </div>

                <div className="list-view">
                    {
                        eventList ? eventList.map((event, index) => (
                            <div className="list-item" key={index}>
                                <div className="list-date">{formatDate(event.from_date)} - {formatDate(event.to_date)}, {date.year}</div>
                                <div className="list-event">
                                    <div className="event"
                                        style={{backgroundColor:event.leave_type.name === 'Personal' ? '#0d6efd'
                                            : event.leave_type.name === 'Vacation' ? '#198754'
                                                : event.leave_type.name === 'Sick Leave' ? '#6c757d'
                                                    : ''}}
                                    >
                                        {event.leave_type.name}
                                    </div>
                                    {event.employee.user.first_name}  {event.employee.user.last_name}
                                </div>
                            </div>
                        ))
                            : (
                                <div>No Data</div>
                            )
                    }

                </div>
            </LoadMask>

        </div>
    );
};

export default Calendar;

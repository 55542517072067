import React, { useState } from 'react'
import Modal from "react-responsive-modal";
import { SingleDatePicker } from 'react-dates';
import { apiDriverApp } from '../../../../../../libs/apiDriverApp';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

export const DownloadModal = ({ showModal, handleCloseModal, getTotalDays}) => {

    const [focused, setFocused] = useState(false);
    const [focused2, setFocused2] = useState(false);
    const [formValues, setFormValues] = useState({
        startDate: '',
        endDate: '',
    });

    const handleChange = (name, value) => {
        setFormValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        const firstDay = formValues.startDate.format('YYYY-MM-DD')
        const lastDay = formValues.endDate.format('YYYY-MM-DD')
        try {
            const { data } = await apiDriverApp.get(`api/hr/leave/?from_date=${firstDay}&to_date=${lastDay}`)
            const formattedData = data.map(request => ({
                "Employee#": request.employee.user.username || '',
                Name: `${request.employee.user.first_name} ${request.employee.user.last_name}`|| '',
                Schedule: request.employee && request.employee.resource && request.employee.resource.calendar
                ? request.employee.resource.calendar.name
                : '',
                "Leave Type": request.leave_type.name || '',
                "Start Date": request.from_date,
                "End Date": request.to_date,
                Days: getTotalDays(request.from_date,request.to_date),
                Status: request.status || '',
                "Status Update": `${request.latest_status.date_changed.slice(0, 16).replace("T", " ")} ${request.latest_status.changed_by}`
            }));
            const ws = XLSX.utils.json_to_sheet(formattedData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Drivers");
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const excelBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            saveAs(excelBlob, "driver_list.xlsx");
            handleCloseModal()
        } catch (error) {
            console.log(error)
            handleCloseModal()
        }
    }


    return (
        <Modal
            open={showModal}
            onClose={handleCloseModal}
            showCloseIcon={false}
            classNames={{ modal: "modal-sm" }}
        >
            <div className="d-flex flex-column">
                <div className="mb-2">
                    <label>Start Date</label>
                    <div className='form-control p-1'>
                        <SingleDatePicker
                            id="date_input"
                            date={formValues.startDate}
                            onDateChange={(newDate) => handleChange('startDate', newDate)}
                            focused={focused}
                            onFocusChange={({ focused }) => setFocused(focused)}
                            numberOfMonths={1}
                            isOutsideRange={() => false}
                            placeholder='2025-01-01'
                        />

                    </div>
                </div>
                <div className="mb-2">
                    <label>End Date</label>
                    <div className='form-control p-1'>
                        <SingleDatePicker
                            id="date_input2"
                            date={formValues.endDate}
                            onDateChange={(newDate) => handleChange('endDate', newDate)}
                            focused={focused2}
                            onFocusChange={({ focused }) => setFocused2(focused)}
                            numberOfMonths={1}
                            isOutsideRange={(day) =>
                                formValues.startDate ? day.isBefore(formValues.startDate, 'day') : false
                            }
                            placeholder='2025-01-02'
                        />
                    </div>
                </div>
                <div className="d-flex flex-row mt-4 justify-content-between">
                    <button
                        className="btn btn-secondary"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={handleSubmit}
                    >
                        Download
                    </button>
                </div>
            </div>
        </Modal>
    )
}

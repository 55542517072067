import React, { useEffect, useState } from 'react'
import "./Statistics.css";
import Donut from './components/Donut';
import ColumnChart from './components/ColumnChart';
import { RequestSummary } from './components/RequestSummary';
import { apiDriverApp } from '../../../../../libs/apiDriverApp';
import LoadMask from '../../../Utils/LoadMask';

export const StatisticsBoard = () => {
    const [requests, setRequests] = useState(false);
    const [categories, setCategories] = useState(false);
    const [drivers, setDrivers] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [chartData2, setChartData2] = useState([]);
    const [avgDays, setAvgDays] = useState(0);
    const [loading, setLoading] = useState(false);

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const categorizeRequests = (data) => {
        let pending = 0, denied = 0, approved = 0, sick = 0, personal = 0, vacation = 0;

        data.forEach(request => {
            if (request.status === 'approved') {
                approved++;
            } else if (request.status === 'denied') {
                denied++;
            } else if (request.status === 'sent') {
                pending++;
            }

            if (request.leave_type && request.leave_type.name) {
                if (request.leave_type.name === 'Personal') {
                    personal++;
                } else if (request.leave_type.name === 'Sick Leave') {
                    sick++;
                } else if (request.leave_type.name === 'Vacation') {
                    vacation++;
                }
            }
        });

        return { pending, denied, approved, sick, personal, vacation };
    };

    const getTotalDays = (from_date, to_date) => {
        const fromDate = new Date(from_date);
        const toDate = new Date(to_date);
        const diffTime = toDate - fromDate;
        const totalDays = diffTime / (1000 * 60 * 60 * 24) + 1;
        return totalDays
    }

    const formatData = (requests) => {
        const formattedData = requests.map(request => ({
            user: request.employee.user.username || '',
            fullName: `${request.employee.user.first_name} ${request.employee.user.last_name}`,
            type: request.leave_type.name || '',
            totalDays: getTotalDays(request.from_date, request.to_date)
        }))

        return formattedData
    }

    const getFirstDaySixMonthsAgo = () => {
        const today = new Date();
        const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 5, 1);
        return sixMonthsAgo;
    };

    const groupRequestsByMonth = (requests) => {
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const counts = {};

        requests.forEach((request) => {
            const [startYear, startMonth, startDay] = request.from_date.split("-").map(Number);
            const [endYear, endMonth, endDay] = request.to_date.split("-").map(Number);

            let current = new Date(Date.UTC(startYear, startMonth - 1, startDay));
            const end = new Date(Date.UTC(endYear, endMonth - 1, endDay));

            while (current <= end) {
                const yearMonth = `${current.getUTCFullYear()}-${String(current.getUTCMonth() + 1).padStart(2, "0")}`;

                if (!counts[yearMonth]) {
                    counts[yearMonth] = { Personal: 0, Vacation: 0, Sick: 0 };
                }

                if (request.leave_type.name === "Personal") {
                    counts[yearMonth].Personal += 1;
                } else if (request.leave_type.name === "Vacation") {
                    counts[yearMonth].Vacation += 1;
                } else if (request.leave_type.name === "Sick Leave") {
                    counts[yearMonth].Sick += 1;
                }
                current = new Date(Date.UTC(current.getUTCFullYear(), current.getUTCMonth() + 1, 1));
            }
        });

        const result = Object.keys(counts)
            .sort()
            .map((yearMonth) => {
                const [year, month] = yearMonth.split("-");
                return [
                    monthNames[parseInt(month, 10) - 1], 
                    counts[yearMonth].Personal,
                    counts[yearMonth].Vacation,
                    counts[yearMonth].Sick
                ];
            });

        return result;
    };

    const get6MonthData = async () => {
        try {
            const today = new Date();
            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split("T")[0];
            const passDate = getFirstDaySixMonthsAgo()
            const firstDay = passDate.toISOString().split("T")[0];
            const { data } = await apiDriverApp.get(`api/hr/leave/?from_date=${firstDay}&to_date=${lastDay}`);
            const groupedRequests = groupRequestsByMonth(data)
            setChartData2(groupedRequests)
        } catch (error) {
            console.log(error)
        }
    }

    const getMonthlyData = async () => {
        setLoading(true)
        try {
            const today = new Date();
            const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
                .toISOString()
                .split("T")[0];

            const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
                .toISOString()
                .split("T")[0];

            const { data } = await apiDriverApp.get(`api/hr/leave/?from_date=${firstDay}&to_date=${lastDay}`);
            setRequests(data)
            const categorizeReq = categorizeRequests(data)
            const donutData = [
                { type: "Personal", percentage: (categorizeReq.personal / data.length) * 100 },
                { type: "Vacation", percentage: (categorizeReq.vacation / data.length) * 100 },
                { type: "Sick", percentage: (categorizeReq.sick / data.length) * 100 }
            ];

            setChartData(donutData)
            setCategories(categorizeReq)
            const individualRequests = (formatData(data))
            const avgDay = Math.round(
                (individualRequests.reduce((sum, user) => sum + user.totalDays, 0)) / individualRequests.length
              );
            setAvgDays(avgDay)
            const groupedRequests = individualRequests.reduce((acc, request) => {
                const { user, fullName, type, totalDays } = request;

                if (!acc[user]) {
                    acc[user] = {
                        user,
                        fullName,
                        totalDays: 0,
                        requests: 0,
                        vacation: 0,
                        sick: 0,
                        personal: 0
                    };
                }

                acc[user].totalDays += totalDays;
                acc[user].requests++;

                if (type.toLowerCase() in acc[user]) {
                    acc[user][type.toLowerCase()]++;
                }

                return acc;
            }, {});

            const result = Object.values(groupedRequests);

            setDrivers(result);
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        get6MonthData()
        getMonthlyData()
    }, [])

    return (
        <div className='stats-content'>
            <LoadMask loading={loading} light>
                <div className="stats-grid">
                    <div className="stat-card total-card">
                        <div className="stat-title">Total Requests</div>
                        <div className="stat-value">{requests ? requests.length : 0}</div>
                        <div className="stat-percentage">{monthNames[new Date().getMonth()]} {new Date().getFullYear()}</div>
                    </div>
                    <div className="stat-card approved-card">
                        <div className="stat-title">Approved</div>
                        <div className="stat-value">{categories ? categories.approved : 0}</div>
                        <div className="stat-percentage">
                            {requests && categories
                                ? `${((categories.approved / requests.length) * 100).toFixed(1)}% of total`
                                : "No Data"}
                        </div>
                    </div>
                    <div className="stat-card pending-card">
                        <div className="stat-title">Pending</div>
                        <div className="stat-value">{categories ? categories.pending : 0}</div>
                        <div className="stat-percentage">
                            {requests && categories
                                ? `${((categories.pending / requests.length) * 100).toFixed(1)}% of total`
                                : "No Data"}
                        </div>
                    </div>
                    <div className="stat-card denied-card">
                        <div className="stat-title">Denied</div>
                        <div className="stat-value">{categories ? categories.denied : 0}</div>
                        <div className="stat-percentage">
                            {requests && categories
                                ? `${((categories.denied / requests.length) * 100).toFixed(1)}% of total`
                                : "No Data"}
                        </div>
                    </div>
                </div>

                <h3 className='text-left font-bold'>Leave Type Distribution</h3>

                <div className="progress-container">
                    <div className="progress-label">
                        <span>Overall distribution by leave type</span>
                    </div>
                    <div className="my-progress-bar-container">
                        {
                            requests && categories && (
                                <div style={{ display: 'flex', width: '100%' }}>
                                    {categories.personal > 0 && (
                                        <div className="my-progress-bar my-personal-bar"
                                            style={{
                                                width: `${Math.max((categories.personal / requests.length) * 100, 5)}%`,
                                                maxWidth: "75%"
                                            }}>
                                            Personal ({categories.personal})
                                        </div>
                                    )}

                                    {categories.vacation > 0 && (
                                        <div className="my-progress-bar my-vacation-bar"
                                            style={{
                                                width: `${Math.max((categories.vacation / requests.length) * 100, 5)}%`,
                                                maxWidth: "75%"
                                            }}>
                                            Vacation ({categories.vacation})
                                        </div>
                                    )}

                                    {categories.sick > 0 && (
                                        <div className="my-progress-bar my-sick-bar"
                                            style={{
                                                width: `${Math.max((categories.sick / requests.length) * 100, 5)}%`,
                                                maxWidth: "75%"
                                            }}>
                                            Sick ({categories.sick})
                                        </div>
                                    )} </div>
                            )
                        }
                    </div>
                </div>

                <div className='chart-container'>
                    <div className='chart'>
                        <ColumnChart data={chartData2} />
                    </div>

                    <div className='chart'>
                        <Donut data={chartData} averageDays={avgDays} />
                    </div>

                </div>

                <RequestSummary data={drivers} />
            </LoadMask>


        </div>
    )
}

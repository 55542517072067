import React from 'react'
import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cellOverlapShiftContainer: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        height: "100%", // Asegura que ocupe toda la celda
        width: "100%",
        padding: 0,
        borderTop: "0.2px solid #f5f0f0",
        borderRight: "0.2px solid #f5f0f0",
        borderBottom: 'none'
    },
    shiftStart: {
        color: "white",
        fontSize: 12,
        padding: 5,
        paddingRight: 0,
        margin: 0,
        width: "100%",
        textAlign: "right",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        display: "flex", // Habilita flexbox
        alignItems: "center", // Centra verticalmente
        justifyContent: "center", // Centra horizontalmente
        gap: "4px"
    },
}));

export const PartialRight = ({ id, color, name }) => {
    const classes = useStyles();
    return (
        <Grid item xs key={id + 1} style={{ flexGrow: 0.5 }}>
            <div className={classes.cellOverlapShiftContainer}
                style={{
                    borderTop: "0.2px solid #f5f0f0",
                    borderRight: "0.2px solid #f5f0f0"
                }}
            >
                <Paper
                    className={classes.shiftStart}
                    style={{ backgroundColor: color, color: color === '#ffffff' ? "black" : "white", }}
                >
                    {name}
                </Paper>
            </div>
        </Grid>
    )
}

import React from 'react'
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cellOverlapShiftContainer: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        height: "100%", // Asegura que ocupe toda la celda
        width: "100%",
        padding: 0,
        borderTop: "0.2px solid #f5f0f0",
        borderLeft: "0.2px solid #f5f0f0",
        borderBottom: 'none'
    },
    shiftStart: {
        backgroundColor: "#D50032",
        color: "white",
        fontSize: 12,
        padding: 5,
        paddingLeft: 0,
        margin: 0,
        width: "100%",
        textAlign: "left",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        display: "flex", // Habilita flexbox
        alignItems: "center", // Centra verticalmente
        justifyContent: "center", // Centra horizontalmente
        gap: "4px"
    },
}));

export const PartialEmptyLeft = ({ id }) => {
    const classes = useStyles();
    return (
        <Grid item xs key={id + `A`} style={{ flexGrow: 0.5 }}>
            <div className={classes.cellOverlapShiftContainer}>

            </div>
        </Grid>
    )
}

import React from 'react'
import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import ArrowRightIcon from '../../../../icons/arrow-right';
import ArrowLeftIcon from '../../../../icons/arrow-left';

const useStyles = makeStyles((theme) => ({
    cellOverlapContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        padding: 2,
        borderTop: "0.2px solid #f5f0f0",
        borderBottom: 'none'
    },
    daytimeOverlap: {
        fontSize: 12,
        padding: 5,
        margin: 0,
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "4px",
        boxShadow: 'none'
    },
}));

export const HourlyShift = ({id, from, to, color='white'}) => {
    const classes = useStyles();
    const formatHour = (hour) => String(hour).padStart(2, '0') + ":00";
    return (
        <Grid item xs key={id} style={{ flexGrow: 1 }}>
            <div className={classes.cellOverlapContainer} style={{backgroundColor:color}}>
                <Paper className={classes.daytimeOverlap} style={{border:"1px solid #f5f0f0", color:'white', backgroundColor:'#FF9AB2'}}>
                {formatHour(from)} <ArrowRightIcon size={12} /> <ArrowLeftIcon size={12} /> {formatHour(to)}
                </Paper>

            </div>
        </Grid>
    )
}

import React from 'react'
import { Grid, Paper, makeStyles } from "@material-ui/core";
import ArrowRightIcon from '../../../../icons/arrow-right';

const useStyles = makeStyles((theme) => ({
    cellOverlapShiftContainerEnd: {
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        height: "100%",
        width: "100%",
        padding: 0,
        borderTop: "0.2px solid #f5f0f0",
        borderBottom: 'none'
      },
    shiftEnd: {
        fontSize: 12,
        padding: 5,
        paddingRight: 0,
        margin: 0,
        width: "100%",
        textAlign: "right",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
        gap: "4px"
      },
}));

export const HourlyShiftEnd = ({id, from, color='white'}) => {
    const classes = useStyles();
    const formatHour = (hour) => String(hour).padStart(2, '0') + ":00";
    return (
        <Grid item xs key={id} style={{ flexGrow: 0.5 }}>
            <div className={classes.cellOverlapShiftContainerEnd} style={{backgroundColor:'#FF9AB2'}}>
                <Paper className={classes.shiftEnd} style={{borderLeft:"1px solid #f5f0f0", borderTop:"1px solid #f5f0f0", borderBottom:"1px solid #f5f0f0", borderRight:"none", color:'white',}}>
                {formatHour(from)} <ArrowRightIcon size={12} /> 
                </Paper>
            </div>
        </Grid>
    )
}

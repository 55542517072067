import React from 'react'
import { Grid, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cellContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        padding: 2,
        borderBottom: 'none'
    },
    daytime: {
        fontSize: 12,
        padding: 5,
        margin: 0,
        width: "100%",
        textAlign: "center",
        boxShadow: 'none'
    },
}));

export const NormalShift = ({ id, color, name, overlap = false }) => {
    const classes = useStyles();
    return (
        <Grid item xs key={id} style={{ flexGrow: 1 }}>
            <div className={classes.cellContainer} 
                 style={{
                    borderTop: "0.2px solid #f5f0f0",
                    borderLeft: overlap ? "none" : "0.2px solid #f5f0f0",
                    borderRight: overlap ? "none" : "0.2px solid #f5f0f0"
                }}
            >
                <Paper className={classes.daytime} style={{ backgroundColor:  color === '#ffffff' ? '#FF9AB2' : color , color: "white", }}>{name}</Paper>
            </div>
        </Grid>
    )
}

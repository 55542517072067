import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { apiDriverApp } from '../../../../libs/apiDriverApp';

const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return Promise.reject("No file selected");

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const arrayBuffer = e.target.result;
                const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: "array" });

                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                const requiredHeaders = [
                    "Email",
                    "FirstName",
                    "Job",
                    "LastName",
                    "MobilePhone",
                    "PinCode",
                    "Username",
                    "Device"
                ];

                const headers = jsonData[0];

                const missingHeaders = requiredHeaders.filter(
                    (header) => !headers.includes(header)
                );

                if (missingHeaders.length > 0) {
                    reject(new Error(`The file uploaded is missing headers: ${missingHeaders.join(", ")}`));
                    return;
                }

                const invalidHeaders = headers.filter(
                    (header) => !requiredHeaders.includes(header)
                );

                if (invalidHeaders.length > 0) {
                    reject(new Error(`The file uploaded contains invalid headers: ${invalidHeaders.join(", ")}`));
                    return;
                }

                const excelData = jsonData.slice(1).map((row) => {
                    const obj = {};
                    headers.forEach((header, index) => {
                        obj[header] = row[index] || null;
                    });
                    return obj;
                });

                resolve(excelData);
            } catch (error) {
                console.error("Error processing the file:", error);
                reject(error);
            }
        };

        reader.readAsArrayBuffer(file);
    });
};

export const downloadDriverList = async () => {
    try {
        const { data } = await apiDriverApp.get('/api/hr/employees/');
       
        if (!data || data.length === 0) {
            console.warn("No hay datos para exportar.");
            return;
        }

        // Filtra y organiza los datos para el Excel
        const formattedData = data.map(driver => ({
            Username: driver.user.username || '',
            // PinCode: driver.pin_code || '',
            Email: driver.user.email || '',
            FirstName: driver.user.first_name || '',
            LastName: driver.user.last_name || '',
            Job: driver.job ? driver.job.name : '',
            MobilePhone: driver.mobile_phone || '',
            "Calendar Name": (driver.resource && driver.resource.calendar) ? driver.resource.calendar.name : ''
        }));

        // Crea la hoja de trabajo (worksheet) y el libro de trabajo (workbook)
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Drivers");

        // Genera el archivo y lo descarga
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        saveAs(excelBlob, "driver_list.xlsx");
    } catch (error) {
        console.error("Error descargando la lista de conductores:", error);
    }
};


const validateRequiredFields = (data, requiredFields) => {
    const invalidRecords = [];

    data.forEach((item, index) => {
        const isValid = requiredFields.every(field => item[field] && String(item[field]).trim() !== '');
        if (!isValid) {
            invalidRecords.push(`Row ${index + 1}`); 
        }
    });

    return invalidRecords; 
};

const validateMatchingRecords = (data, dbMap) => {
    const matchingRecords = [];

    data.forEach((item, index) => {
        const usernameKey = String(item.Username || "").trim().toLowerCase();
        const emailKey = String(item.Email || "").trim().toLowerCase();

        if (dbMap.has(usernameKey) || [...dbMap.values()].includes(emailKey)) {
            matchingRecords.push(`Row ${index + 1}`);
        }
    });

    return matchingRecords;
};

const createNewDrivers = async (dbMap, uploadedData) => {
    
    const requests = uploadedData.map(async (item) => {
        const match = dbMap.get(String(item.Username));

        if (!match) {
            const body = {
                username: item.Username,
                pin_code: item.PinCode,
                email: item.Email || 'no@email.com',
                first_name: item.FirstName,
                last_name: item.LastName,
                job: item.Job,
                work_email: item.Email || 'no@email.com',
                work_phone: item.MobilePhone,
                mobile_phone: item.MobilePhone,
            };

            if(item.Device){
                body.device_type = item.Device
            }
            try {
                await apiDriverApp.post('api/hr/employees/', body);
                return { username: item.Username, success: true };
            } catch (error) {
                console.error(`Error processing employee ${item.Username}:`, error.response);
                return { username: item.Username, error: error.message };
            }
        } else {
            console.log(`This driver already exists: ${item.Username}`);
            return { username: item.Username, success: false, message: 'Already exists' };
        }
    });

    const results = await Promise.all(requests);
    const failed = results.filter(res => res.error);

    if (failed.length > 0) {
        throw new Error(`Could not create: ${failed.map(e => e.username).join(', ')}`);
    }

    return results;
};

export const onFileUpload = async (event) => {
    try {
        const loadingAlert = Swal.fire({
            title: 'Uploading...',
            text: 'Please wait while the file is being uploaded and processed.',
            allowOutsideClick: false,
            allowEscapeKey: false,
            onBeforeOpen: () => {
                Swal.showLoading();
            },
        });
        const results = await handleFileChange(event);
        console.log("Processed Data:", results);

        const invalidRecords = validateRequiredFields(results, ['Username', 'PinCode', 'Job']);
        if (invalidRecords.length > 0) {
            Swal.fire(
                'Validation Error',
                `The following records are missing required fields (Username or PinCode):\n${invalidRecords.join(', ')}`,
                'error'
            );
            return;
        }

        const { data: dbDrivers } = await apiDriverApp.get('/api/hr/employees/');
        const dbMap = new Map(
            dbDrivers.map(item => [String(item.user.username).trim().toLowerCase(), String(item.user.email).trim().toLowerCase()])
        );

        const existingRecords = validateMatchingRecords(results, dbMap)
        if (existingRecords.length > 0) {
            await Swal.fire(
                'Validation Error',
                `The following records already exist in the database:\n${existingRecords.join(', ')}`,
                'error'
            );
            return; 
        }
        
        await createNewDrivers(dbMap, results);
        event.target.value = "";
        Swal.close();
        Swal.fire('Success', 'The file was uploaded successfully', 'success');

    } catch (error) {
        console.error("Error during file upload:", error);
        Swal.close();
        if (error.response) {
            Swal.fire('Error', error.response.data.detail || 'An error occurred', 'error');
        } else if (error.request) {
            Swal.fire('Network Error', 'No response from server. Please try again later.', 'error');
        } else {
            Swal.fire('Error', error.message || 'An unexpected error occurred', 'error');
        }
        event.target.value = "";
    }
};

export const getFullName = (firstName = 'no', lastName = 'data')=>{
    const format1 = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    const format2 = lastName.charAt(0).toUpperCase() + lastName.slice(1);
    return `${format1} ${format2}`
}
import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal';
import IconFloppyDisk from '../../../icons/floppy-disk';
import IosIcon from '../../../icons/ios-icon'
import AndroidIcon from '../../../icons/android-icon'
import Select from "react-select";
import swal from 'sweetalert2';
import { apiDriverApp } from '../../../../libs/apiDriverApp';

export const ModalDriverForm = ({ showModal, handleCloseModal, initialValues, title, jobs, schedules, getDrivers }) => {

    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        job: '',
        schedule: '',
        username: '',
        pin: '',
        pin2: '',
        link: 'none'
    });

    const handleChange = (name, value) => {
        setFormValues((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (initialValues) {
            setFormValues({
                firstName: initialValues.user.first_name || '',
                lastName: initialValues.user.last_name || '',
                phone: initialValues.mobile_phone || '',
                email: initialValues.user.email || '',
                job: initialValues.job ? initialValues.job.name : '',
                schedule: initialValues.resource && initialValues.resource.calendar ? initialValues.resource.calendar.id : '',
                username: initialValues.user.username || '',
                link: 'none',
                pin:'',
                pin2:''
            });
        } else {
            setFormValues({
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                job: '',
                schedule: '',
                username: '',
                link: 'none',
                pin:'',
                pin2:''
            });
        }
    }, [initialValues]);

       const updateScheduleResource = async (scheduleId, driverId) => {
        const selectedSchedule = schedules.find(schedule => schedule.id == scheduleId);
        const currentDrivers = selectedSchedule.drivers.map(driver=>driver.resource_id);
        const updatedResources = [...currentDrivers, driverId];        

            try {
                await apiDriverApp.post(`api/hr/schedule/${scheduleId}/manage_resources/`, {
                    resource_ids: updatedResources
                })
            } catch (error) {
                console.log(error)
            }
        };

        const removeDriverFromSchedule = async(scheduleId, driverId) => {
            const selectedSchedule = schedules.find(schedule => schedule.id == scheduleId);
            if (!selectedSchedule) return;
            const currentDrivers = selectedSchedule.drivers.map(driver=>driver.resource_id);
            const updatedDrivers = currentDrivers.filter(id => id !== driverId);
                try {
                    await apiDriverApp.post(`api/hr/schedule/${scheduleId}/manage_resources/`, {
                        resource_ids: updatedDrivers
                    })
                } catch (error) {
                    console.log(error)
                }
            };

    const handleSubmit = async () => {
        // Validaciones iniciales
        if (!formValues || formValues.firstName === '' ||
            formValues.lastName === '' || formValues.phone === '' ||
            formValues.email === '' || formValues.job === '' ||
            formValues.username === '') {
            swal("Missing Fields", 'Please verify that required fields are filled', "error");
            return;
        }

        if (formValues.pin && formValues.pin !== '') {  
            if (formValues.pin.length !== 4) {  
                swal("Pin code needs to be 4 digits", "Please verify that pin code is correct", "error");  
                return; 
            }  
            if (formValues.pin !== formValues.pin2) {
                swal("Pins do not match", "Please verify that pin code is correct", "error");
                return; 
            }
        }        

        try {
            if (initialValues) {
                const initialJob = initialValues.job ? initialValues.job.name : '';
                const initialSchedule = initialValues.resource && initialValues.resource.calendar ? initialValues.resource.calendar.name : '';

                const changes = {};
                changes.username = initialValues.user.username;
                if (formValues.pin !== '') changes.pin_code = formValues.pin;
                if (formValues.email !== initialValues.user.email) changes.email = formValues.email;
                if (formValues.firstName !== initialValues.user.first_name) changes.first_name = formValues.firstName;
                if (formValues.lastName !== initialValues.user.last_name) changes.last_name = formValues.lastName;
                if (formValues.job !== initialJob) changes.job = formValues.job;
                if (formValues.phone !== initialValues.mobile_phone) changes.mobile_phone = formValues.phone;
                if (formValues.link !== initialValues.link && formValues.link !== 'none') changes.device_type = formValues.link;
                // Editar
                if (Object.keys(changes).length > 0) {
                    const { data } = await apiDriverApp.post('api/hr/employees/update_or_create/', changes);
                  }

                if (formValues.schedule !== initialSchedule){
                    if(formValues.schedule === -3){
                        await removeDriverFromSchedule(initialValues.resource.calendar.id, initialValues.resource.id)
                    }else{
                        await updateScheduleResource(formValues.schedule, initialValues.resource.id)
                    }
                  }

            } else {
                // Crear
                const body = {
                    username: formValues.username,
                    email: formValues.email,
                    first_name: formValues.firstName,
                    last_name: formValues.lastName,
                    job: formValues.job,
                    mobile_phone: formValues.phone,
                    pin_code: formValues.pin
                }
                if (formValues.link !== 'none'){
                    body.device_type = formValues.link;
                }
                const { data } = await apiDriverApp.post('api/hr/employees/update_or_create/', body );

                if (formValues.schedule !== ''){
                    await updateScheduleResource(formValues.schedule, data.resource.id)
                  }
            }

            // Si la solicitud tiene éxito
            swal('Success', `Driver ${initialValues ? 'edited' : 'created'} with success`, 'success')
            getDrivers();
            handleCloseModal();
        } catch (error) {
            console.error("Error:", error);

            if (error.response) {
                swal("Error", error.response.data.error || "An error occurred", "error");
            } else if (error.request) {
                swal("Network Error", "No response from server. Please try again later.", "error");
            } else {
                swal("Error", "An unexpected error occurred", "error");
            }
        }
    };

    return (
            <Modal
                open={showModal}
                onClose={handleCloseModal}
                showCloseIcon={false}
                blockScroll={false}
                styles={{ modal: { minWidth: '35%', borderRadius: '8px', marginTop: 50, padding: 0 } }}
            >
                <div className="d-flex row m-0 justify-content-between align-items-center px-4 pt-3">
                    <div className='col-6'>
                        <h4 style={{ fontWeight: 'bold' }}>{title}</h4>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">

                        <button onClick={handleCloseModal} className="btn d-flex align-items-center rounded shadow-sm px-3 py-2 me-3" style={{ backgroundColor: '#D50032', color: 'white' }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                                X
                            </div>
                            <span className="ml-1">Close</span>
                        </button>

                        <button className="btn border border-dark rounded shadow-sm px-3 py-2 d-flex align-items-center ml-3" onClick={handleSubmit}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                                <IconFloppyDisk className='h-100' />
                            </div>
                            <span className="ml-1" style={{ fontWeight: 'bold' }}>Save</span>
                        </button>
                    </div>
                </div>

                <hr />

                <div className="container px-5">
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <h6>First Name</h6>
                            <input
                                placeholder='Mike'
                                className='form-control'
                                value={formValues.firstName}
                                onChange={(e) => handleChange('firstName', e.target.value)}
                            />
                        </div>
                        <div className="col-md-6 ">
                            <h6>Last Name</h6>
                            <input
                                placeholder='Perry'
                                className='form-control'
                                value={formValues.lastName}
                                onChange={(e) => handleChange('lastName', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <h6>Mobile Phone</h6>
                            <input
                                placeholder='703-456-7890'
                                className='form-control'
                                value={formValues.phone}
                                onChange={(e) => handleChange('phone', e.target.value)}
                            />
                        </div>
                        <div className="col-md-6 ">
                            <h6>Email</h6>
                            <input
                                placeholder='no@email.com'
                                className='form-control'
                                value={formValues.email}
                                onChange={(e) => handleChange('email', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <h6>Assigned Job</h6>
                            <Select
                                options={jobs}
                                value={formValues.job}
                                onChange={(option) => handleChange('job', option.value)}
                                placeholder="Shuttle Driver - WAS"
                                clearable={false}
                            />
                        </div>
                        <div className="col-md-6 ">
                            <h6>Schedule</h6>
                            <Select
                                options={schedules}
                                value={schedules.find(option => option.id === formValues.schedule) || null}
                                onChange={(option) => handleChange('schedule', option ? option.id : null)}
                                placeholder="Schedule A"
                                clearable={false}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <h6>Username / Driver ID</h6>
                            <input
                                placeholder='9000'
                                className='form-control'
                                value={formValues.username}
                                onChange={(e) => handleChange('username', e.target.value)}
                                disabled={initialValues}
                            />
                        </div>
                        <div className="col-md-6 ">
                            <h6>PIN code</h6>
                            <input
                                placeholder='****'
                                type="password"
                                className='form-control'
                                value={formValues.pin}
                                onChange={(e) => handleChange('pin', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                border: '1px solid #ccc',
                                borderRadius: '5px',
                                width: '100%'
                            }}>
                                <label style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '10px',
                                    borderBottom: '1px solid #ddd',
                                    minHeight: '40px', 
                                    margin: 0
                                }}>
                                    <input type="radio" value="none" checked={formValues.link === 'none'} style={{ marginLeft: 10, marginRight: 10 }} onChange={(e) => handleChange('link', e.target.value)} />
                                    No download link
                                </label>
                                <label style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '10px',
                                    borderBottom: '1px solid #ddd',
                                    minHeight: '40px',
                                    margin: 0
                                }}>
                                    <input type="radio" value="ios" checked={formValues.link === 'ios'} style={{ marginLeft: 10, marginRight: 10 }} onChange={(e) => handleChange('link', e.target.value)} />
                                    iOS <IosIcon className='mb-1 ml-1' />
                                </label>
                                <label style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '10px',
                                    minHeight: '40px',
                                    margin: 0
                                }}>
                                    <input type="radio" value="android" checked={formValues.link === 'android'} style={{ marginLeft: 10, marginRight: 10 }} onChange={(e) => handleChange('link', e.target.value)} />
                                    Android <AndroidIcon className='ml-1' />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <h6>Confirm PIN</h6>
                            <input
                                placeholder='****'
                                type="password"
                                className='form-control'
                                value={formValues.pin2}
                                onChange={(e) => handleChange('pin2', e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </Modal>

    )
}

import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal';
import IconFloppyDisk from '../../../icons/floppy-disk';
import { connect } from 'react-redux';
import { apiDriverApp } from '../../../../libs/apiDriverApp';
import app from '../../../../libs/apiClient';
const emailService = app.service("/sendGrid");
import Select from "react-select";
import { SingleDatePicker } from 'react-dates';
import swal from 'sweetalert2';
import 'react-dates/lib/css/_datepicker.css';
import './styles.css';

const ModalNewTimeOffRequest = ({ showModal, handleCloseModal, request, typeOptions, drivers, getLeaveRequests }) => {

  const [focused, setFocused] = useState(false);
  const [focused2, setFocused2] = useState(false);

  const [formValues, setFormValues] = useState({
    driver: '',
    leaveType: '',
    startDate: '',
    endDate: '',
  });

  const handleChange = (name, value) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!formValues || formValues.driver === '' || formValues.leaveType === ''
      || formValues.startDate === '' || formValues.endDate === ''
    ) {
      swal("Empty Fields", 'Please verify that all fields are filled', "error");
      return;
    }

    try {
      const body = {
        "employee_id": formValues.driver,
        "leave_type_id": formValues.leaveType,
        "from_date": formValues.startDate.format('YYYY-MM-DD'),
        "to_date": formValues.endDate.format('YYYY-MM-DD')
      }
      const data = await apiDriverApp.post(`api/hr/leave/`, body);
      // if (data.status == 200) {
      //   emailService.create(
      //     {
      //       from: {
      //         email: "driverapp-timeoff@connectbbc.com",
      //       },
      //       personalizations: [{
      //         to: [{ email: 'drivers@connectbbc.com' }],
      //         dynamic_template_data: {
      //           subject: `New Time-Off Request`,
      //           title: `New time-off request for ${user?.first_name} ${user?.last_name} (${user?.username})`,
      //           message: `New time-off request received for ${user?.first_name} ${user?.last_name}(${user?.username}) from ${from_date} through ${to_date}. You can click the link below to view now.`,
      //           email_link: 'https://portal.connectbbc.com/#/settings/driver-schedules/time-off',
      //           email_link_text:'Go to dashboard'
      //         }
      //       }],
      //       template_id: 'd-50889e22ce0041d8b5255f99a900d35f',
      //     }
      //   )
      // }
      getLeaveRequests()
      handleCloseModal()
    } catch (error) {
      console.error("Error during request:", error);

      if (error.response) {
        swal("Error", error.response.data.error || "An error occurred", "error");
      } else if (error.request) {
        swal("Network Error", "No response from server. Please try again later.", "error");
      } else {
        swal("Error", "An unexpected error occurred", "error");
      }
    }
  }

  return (
    <React.Fragment>
      <Modal
        open={showModal}
        onClose={handleCloseModal}
        showCloseIcon={false}
        styles={{ modal: { minWidth: '28%', borderRadius: '8px', marginTop: 50, padding: 0, overflow: 'visible' } }}
      >
        <div className="d-flex row m-0 justify-content-between align-items-center px-3 pt-3">
          <div className='col-6 p-0'>
            <h4 style={{ fontWeight: 'bold' }}>New Time Off Request</h4>
          </div>

          <div className="d-flex justify-content-between align-items-center">

            <button onClick={handleCloseModal} className="btn d-flex align-items-center rounded shadow-sm px-3 py-2 me-3" style={{ backgroundColor: '#D50032', color: 'white' }}>
              <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                X
              </div>
              <span className="ml-1">Close</span>
            </button>

            <button className="btn border border-dark rounded shadow-sm px-3 py-2 d-flex align-items-center ml-3" onClick={handleSubmit}>
              <div style={{ display: 'flex', alignItems: 'center', height: '0.8rem' }}>
                <IconFloppyDisk className='h-100' />
              </div>
              <span className="ml-1" style={{ fontWeight: 'bold' }}>Save</span>
            </button>
          </div>
        </div>

        <hr />

        <div className="container px-md-5">
          <div className="row mb-3">
            <div className="col-md-6">
              <h6 className='font-bold'>Driver</h6>
              <Select
                options={drivers}
                value={drivers.find((option) => option.id === formValues.driver)}
                onChange={(option) => handleChange('driver', option.id)}
                placeholder="Driver A"
                clearable={false}
              />
            </div>
            <div className="col-md-6 ">
              <h6 className='font-bold'>Request type</h6>
              <Select
                options={typeOptions}
                value={typeOptions.find((option) => option.id === formValues.leaveType)}
                onChange={(option) => handleChange('leaveType', option.id)}
                placeholder="Leave type"
                clearable={false}
              />
            </div>
          </div>

          <div className="row mb-3 ">
            <div className="col-12 col-md-6">
              <h6 className='font-bold'>Start Date</h6>
              <div className='form-control p-1'>
                <SingleDatePicker
                  id="date_input"
                  date={formValues.startDate}
                  onDateChange={(newDate) => handleChange('startDate', newDate)}
                  focused={focused}
                  onFocusChange={({ focused }) => setFocused(focused)}
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  placeholder='2025-01-01'
                />

              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <h6 className='font-bold'>End Date</h6>
              <div className='form-control p-1'>
                <SingleDatePicker
                  id="date_input2"
                  date={formValues.endDate}
                  onDateChange={(newDate) => handleChange('endDate', newDate)}
                  focused={focused2}
                  onFocusChange={({ focused }) => setFocused2(focused)}
                  numberOfMonths={1}
                  isOutsideRange={(day) =>
                    formValues.startDate ? day.isBefore(formValues.startDate, 'day') : false
                  } 
                  placeholder='2025-01-02'
                />
              </div>
            </div>
          </div>
        </div>

      </Modal>
    </React.Fragment>
  )
};

const mapStateToProps = (state) => ({
  user: state.login.userInfo,
});

export default connect(mapStateToProps)(ModalNewTimeOffRequest);


import React from 'react'
import { Grid, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    cellOverlapShiftContainer: {
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        height: "100%", 
        width: "100%",
        padding: 0,
        borderTop: "0.2px solid #f5f0f0",
        borderBottom: 'none'
      },
    shiftStart: {
        backgroundColor: "#D50032",
        color: "white",
        fontSize: 12,
        padding: 5,
        paddingLeft: 0,
        margin: 0,
        width: "100%",
        textAlign: "left",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        display: "flex", 
        alignItems: "center",
        justifyContent: "center", 
        gap: "4px"
      },
}));

export const FirstlCell = ({ id, color, name }) => {
    const classes = useStyles();
    return (
        <Grid item xs key={id} style={{ flexGrow: 0.5, display:'flex'}}>
            <div className={classes.cellOverlapShiftContainer}
                style={{
                    borderTop: "0.2px solid #f5f0f0",
                    borderRight: "0.2px solid #f5f0f0"
                }}
            >
                <Paper
                    className={classes.shiftStart}
                    style={{ backgroundColor: color, color: color === '#ffffff' ? "black" : "white", }}
                >
                    {name}
                </Paper>
            </div>
        </Grid>
    )
}
import React from 'react'
import Modal from 'react-responsive-modal';
import { IconTrashAlt } from '../../../icons/trash';
import IconWarning from '../../../icons/warning';
import IconBan from '../../../icons/cancel-ban';
import { apiDriverApp } from '../../../../libs/apiDriverApp';

export const ConfirmationModal = ({ showModal, handleCloseModal, inactivate, user, setInactiveDrivers, setDrivers}) => {

    const updateDriverStatus = async () => {
        try {
          await apiDriverApp.post('api/hr/employees/update_or_create/', {
            username: user.user.username,
            active: user.active === 1 ? false : true
          });
      
          if (user.active === 1) {
          
            setDrivers(prev => prev.filter(driver => driver.id !== user.id));
            setInactiveDrivers(prev => [...prev, { ...user, active: 0 }]);

          } else {
            
            setInactiveDrivers(prev => prev.filter(driver => driver.id !== user.id));
            setDrivers(prev => [...prev, { ...user, active: 1 }]);
          }
          handleCloseModal()
        } catch (error) {
          console.log(error);
        }
      };

    return (
            <Modal
                open={showModal}
                onClose={handleCloseModal}
                showCloseIcon={false}
                blockScroll={false}
                styles={{ modal: { minWidth: '25%', borderRadius: '8px', marginTop: 50, padding:0 } }}
            >
                <div className="d-flex row m-0 justify-content-between align-items-center pt-3">
                    <div className='col-6'>
                        <h4 style={{ fontWeight: 'bold' }}>{inactivate ? 'Activate driver' : 'Inactivate driver'}</h4>
                    </div>

                    <div className="d-flex col-6 justify-content-center align-items-center">

                        <button onClick={handleCloseModal} className="btn border border-dark d-flex align-items-center rounded shadow-sm px-3 py-2 me-3">
                            <div style={{ display: 'flex', alignItems: 'center', height: '0.7rem' }}>
                                <IconBan className='h-100' />
                            </div>
                            <span className="ml-1" style={{ fontWeight: 'bold' }}>Close</span>
                        </button>

                        <button onClick={updateDriverStatus} className="btn rounded shadow-sm px-3 py-2 d-flex align-items-center ml-3" style={{ backgroundColor: '#333F48', color: 'white' }}>
                            <span>{inactivate ? 'Activate' : 'Inactivate'}</span>
                        </button>
                    </div>


                </div>

                <hr />

                <div className="container px-5 py-2">
                    <div className="row mb-3 justify-content-center">
                        <div style={{ marginTop: 4 }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '1rem', color: '#FF6C00' }}>
                                <IconWarning className='h-100' />
                            </div>
                        </div>

                        <div style={{ width: 350, marginLeft: 10 }}>
                           {inactivate ? 'Are you sure you want to re-activate this driver?' : 'Are you sure you want to inactivate this driver?'}
                        </div>
                    </div>
                </div>

            </Modal>
    )
}